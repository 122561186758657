// https://stackoverflow.com/questions/18379254/regex-to-split-camel-case
export const splitLower = string => string.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toLowerCase()

export const wrap = (text, indentRem = 0) => {
  if (indentRem) {
    return `<p style="margin-left: ${indentRem}rem">${text}</p>`
  } else {
    return `<p>${text}</p>`
  }
}

function parseFromConnectionString (workspaceConnectionString, key) {
  // returns the value from a workspaceConnectionString's key (case insensitive). like returns the version value, or the URL if any.
  if (workspaceConnectionString && workspaceConnectionString.toLowerCase().includes(`${key.toLowerCase()}=`)) {
    let value = workspaceConnectionString.split(';').find(item => item.toLowerCase().includes(`${key.toLowerCase()}=`)) || ''
    value = value.replace(`${key.toLowerCase()}=`, '').replace(`${key.toUpperCase()}=`, '') // not sure if it'll always be caps? preserve version name casing.
    return value
  } else {
    return ''
  }
}
export function dataConnectionDisplay (dc, shouldWrap = true, makeSmall = true) {
  if (!dc || !dc.workspaceFactory) {
    return ''
  }
  let connection = `${dc.workspaceFactory}: `
  if (dc.workspaceFactory === 'SDE') {
    connection += dc.dataSet
  } else if (dc.workspaceFactory === 'FeatureService') {
    connection += parseFromConnectionString(dc.workspaceConnectionString, 'url') + `/${dc.dataSet}`
  } else {
    let connString = dc.workspaceConnectionString.split('=')[1]
    if (!connString || !connString.length) {
      connString = dc.workspaceConnectionString || ''
    }
    // windows filenames use \, urls for things things like featureService would use /
    // try and keep it consistent
    const slash = connString.includes('/') ? '/' : '\\'
    connection += `${connString}${slash}${dc.dataSet || ''}`
  }

  // add version markup if any, or just carry on
  const version = parseFromConnectionString(dc.workspaceConnectionString, 'version')
  connection += version ? ` <small>(${version})</small>` : '' // add version markup if any, or just carry on

  if (makeSmall) {
    connection = `<small>${connection}</small>`
  }

  return shouldWrap ? wrap(connection) : connection
}

function splitIgnoringQuotes (s, delimiter) {
  // split a string by delimiter, provided the delimiter is not in single or double quotes
  const regex = new RegExp(`([^${delimiter}"']+|"([^"]*)"|'([^']*)')+`, 'g')
  const result = []
  let match
  while ((match = regex.exec(s)) !== null) {
    result.push(match[0].trim())
  }
  return result
}

function parseFieldMappingString (str) {
  // these strings are in a real fucked up format: https://pro.arcgis.com/en/pro-app/latest/arcpy/classes/fieldmappings.htm#C_GUID-0210CBF9-547B-434B-94F4-6C00479753EF
  if (typeof str !== 'string') { return [] }

  const output = []

  // first, split the string into its fields
  const fields = splitIgnoringQuotes(str, ';')

  // for each field, "the first nine values in the string define an output field and are space delimited",
  // but also the first value could have spaces in it (see arc event 72755), which really fucks things up...
  // the second field seems to be consistently wrapped in double quotes. we'll split by unquoted spaces first, and
  // if there's more than the expected 9 values we'll do some extra parsing.
  fields.forEach(field => {
    const row = []
    // split by the first unquoted comma to get the first 9 space-delimited fields, save the rest for later
    const [first9FieldsString, mergeRule, concatenator, ...dataFields] = field.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)

    if (splitIgnoringQuotes(first9FieldsString, ' ').length === 9) {
      // add the 9 fields to the output array
      row.push(...splitIgnoringQuotes(first9FieldsString, ' '))
    } else {
      // we assume that the first field had spaces in it and do the best we can to parse...
      const openQuoteIndex = first9FieldsString.indexOf('"')
      const closeQuoteIndex = first9FieldsString.indexOf('"', openQuoteIndex + 1) // we're assuming there will be no double quotes in the field name itself
      const field1 = first9FieldsString.substring(0, openQuoteIndex - 1)
      const field2 = first9FieldsString.substring(openQuoteIndex + 1, closeQuoteIndex)
      const remainingFields = splitIgnoringQuotes(first9FieldsString.substring(closeQuoteIndex, first9FieldsString.length), ' ')
      row.push(field1, field2, ...remainingFields)
    }

    if (mergeRule) { // mergeRule will be undefined if it wasn't parsed, but if it's here we can add the rest of the params
      row.push(mergeRule, concatenator)
      // remaining fields should come in groups of 4, so let's chunk them into predictable arrays?
      const chunkyArray = Array.from({ length: Math.ceil(dataFields.length / 4) }, (v, i) => dataFields.slice(i * 4, i * 4 + 4))
      // then reduce them back down to the sum of each index, i.e.  reducedArray[0] contains all the first elements of each array
      const reducedArray = chunkyArray.reduce((acc, val) => {
        val.forEach((element, index) => {
          acc[index] = acc[index] ? `${acc[index]}<br>${element}` : `${element}`
        })
        return acc
      }, [])
      row.push(...reducedArray)
    }

    // push this row to the main array, stripping any leading and trailing double quotes from each element in the array
    output.push(row.map(str => str.replace(/^"|"$/g, '')))
  })
  return output
}

export function fieldMappingToTable (fieldMappingString) {
  // https://pro.arcgis.com/en/pro-app/latest/arcpy/classes/fieldmappings.htm#M2_GUID-0210CBF9-547B-434B-94F4-6C00479753EF
  const headers = [ // rows are delimited by ; and then within the rows they're further delimited
    // The first nine values in the string define an output field and are space delimited.
    { name: 'Field Name', description: 'The name of the output field.' },
    { name: 'Alias', description: 'The alias of the output field.' },
    { name: 'Editable', description: 'Whether the output field is editable (true or false).' },
    { name: 'Allow NULL', description: 'Whether the output field supports nulls (true or false).' },
    { name: 'Required', description: 'Whether the output field is required (true or false).' },
    { name: 'Length', description: 'The length of the output field (text fields only).' },
    { name: 'Type', description: 'The field type of the output field.' },
    { name: 'Precision', description: 'The precision of the output field (float and double fields only).' },
    { name: 'Scale', description: 'The scale of the output field (float and double fields only).' },
    // The remaining values define the field map characteristics and are comma delimited.
    { name: 'Merge Rule', description: 'The field map merge rule.' },
    { name: 'Join Delimiter', description: 'The concatenator to join values.' },
    { name: 'Input Path', description: 'The path to the input table.' },
    { name: 'Input Field', description: 'The field name from the input table.' },
    { name: 'Input Start', description: 'The start position of an input text value.' },
    { name: 'Input End', description: 'The end position of an input text value.' }
  ]
  const headerHtml = headers.map(header => `<th title="${header.description}">${header.name}</th>`).join('')

  const rows = parseFieldMappingString(fieldMappingString) // returns array of arrays for the rows...
  let rowsHtml = '' // ...but we want it as an html table body
  rows.forEach(row => { // iterate through each row, ultimately outputting a complete <tr> markup
    const rowCellsHtml = row.map(field => { // first convert the columns of each row into a table cell
      return ['-1', '#'].includes(field) ? '<td></td>' : `<td>${field}</td>` // -1 and # are placeholder values, so don't render them
    }).concat(Array(headers.length - row.length).fill('<td></td>')) // if a row doesn't have all the cells, fill the gap with empty ones
    rowsHtml += `<tr>${rowCellsHtml.join('')}</tr>` // wrap all the td cells in a tr to complete the row
  })

  return `
  <small>
    <table class="table is-striped is-narrow is-hoverable">
      <thead><tr>${headerHtml}</tr></thead>
      <tbody>${rowsHtml}</tbody>
    </table>
  </small>
  ` // return full html table with bulma styles. make it small so it fits better.
}
