import { addS, titleCase } from '../data-utils'
import { wrap, dataConnectionDisplay } from './payload-utils'

/**
 * convert a single edit operation (e.g. edit.creates[0]) to text
 * @param {*} detailed how much text to display
 * @param {*} type created, modified, deleted, invalidated
 * @param {*} op a single edit operation within an edit event
 * @returns string representation of the operation
 */
function opConvert (detailed, type, op) {
  let conversion = ''
  const count = op.featureIDs?.length || 0
  const mapName = op.map?.name || op.mapName || 'Map'
  let layers = Array.isArray(op.layers) ? op.layers : op.layer?.name ? [op.layer.name] : []
  if (!detailed && layers.length > 2) {
    layers = [`${layers[0]} and ${layers.length - 1} others`]
  }
  const dataConnection = op.dataConnection || op.layer?.dataConnection || null

  const editFeatures = count ? `${titleCase(type)} ${count} feature${addS(count)}` : `${titleCase(type)}`
  const editAffected = `on <i>${mapName}</i> on layer${addS(layers)} <i>${layers.join(', ')}</i>`
  conversion += wrap(`${editFeatures} ${editAffected}.`)
  if (detailed && count) {
    conversion += wrap(`Feature ID${addS(count)}: ${op.featureIDs.join(', ')}`)
  }
  if (detailed && dataConnection) {
    conversion += dataConnectionDisplay(dataConnection)
  }
  if (detailed) { // add a little spacing to the end in detailed mode to easier read multiple operations
    conversion += '<br>'
  }
  return conversion
}

// convert a single edit event payload into text
// detailLevel 0=short, 1=full, 2=workflow
export function convertEdit (arcEvent, payload, detailLevel = 0) {
  let opText = ''
  // types of edits with text describing them
  const types = { creates: 'created', modifies: 'modified', deletes: 'deleted', invalidated: 'invalidated' }
  // count edits vs. total to truncate early
  const totalEdits = Object.keys(types).reduce((sum, type) => sum + (payload[type]?.length || 0), 0)
  let currentEdit = 0
  Object.keys(types).some(type => {
    // iterate through each edit type and add lines summarizing what happened
    // use nested .some so we can loop as few times as possible
    return payload[type]?.some((op) => {
      const singleOpText = opConvert(detailLevel, types[type], op)
      opText += singleOpText
      currentEdit += 1
      // if not showing details, truncate output to a total of 3 lines to save time
      if (!detailLevel && currentEdit === 2 && totalEdits !== 2) {
        // write out last summary message
        opText += wrap(`<small>${totalEdits - 2} more row${addS(totalEdits - 2)}...</small>`)
        return true // cut loop short
      }
      return false // continue loop
    })
  })

  // add data about processing time in detailed mode, if we have it.
  if (detailLevel === 1 && !isNaN(arcEvent.sketchTime) && !isNaN(arcEvent.processingTime)) {
    const msToSec = ms => +(ms / 1000).toFixed(2)
    opText += wrap(`<small>${msToSec(arcEvent.sketchTime)} seconds sketching, ${msToSec(arcEvent.processingTime)} seconds processing.</small>`)
  }
  return opText
}
