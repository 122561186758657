import moment from 'moment-timezone'
import { tzMo, nameSort } from '@/utils'

export function userTeamOptions (state) {
  const teams = state.user.teams || []
  const options = teams.map(({ team }) => team)
  return options.sort(nameSort)
}

export function currentTimezoneSetting (state) {
  if (state.user && state.user.settings && state.user.settings.timezone) {
    return state.user.settings.timezone
  } else {
    return moment.tz.guess()
  }
}

export function allowedMetrics () { // navbar
  return ['arc']
}

// date
export function dFormatSetting (state) {
  let setting = 'M/D/YYYY'
  if (state.user && state.user.settings && state.user.settings.dFormat) {
    setting = state.user.settings.dFormat
  }
  return setting
}
// time
export function tFormatSetting (state) {
  let setting = 'h:mm a'
  if (state.user && state.user.settings && state.user.settings.tFormat) {
    setting = state.user.settings.tFormat
  }
  return setting
}
// time w/seconds
export function tsFormatSetting (state, getters) {
  const index = getters.tFormatSetting.indexOf('mm') + 2
  return getters.tFormatSetting.slice(0, index) + ':ss' + getters.tFormatSetting.slice(index)
}

// combined date + time
export function dtFormatSetting (state, getters) {
  return getters.dFormatSetting + ' ' + getters.tFormatSetting
}

// combined date + time w/seconds
export function dtsFormatSetting (state, getters) {
  return getters.dFormatSetting + ' ' + getters.tsFormatSetting
}

export function recentMetrics (state) {
  const now = tzMo()
  const time = (metric) => {
    return tzMo(metric.event_datetime)
  }
  return state.liveMetrics.filter(m => now.diff(time(m), 'minutes') / 60 <= 24)
}

export function todayMetric (state) {
  return () => { // needs constant updating
    const now = tzMo()
    const metrics = state.liveMetrics.filter(metric => {
      const date = metric.date?.isSame ? metric.date : tzMo(metric.date) // not sure but this date sometimes is a string
      return date.isSame(now, 'day')
    })
    return metrics.reduce((sum, metric) => {
      if (metric.type.includes('edit-')) {
        sum += 1
      }
      return sum
    }, 0)
  }
}

export function metricSocketConnected (state) {
  return state.connectedSockets.includes('metrics')
}
export function socketConnected (state) {
  return state.connectedSockets.includes('metrics')
}

export function closestPayPeriodStart (state) {
  // return a moment object representing the first day of the closest pay period fromDate
  // only does forward-calculation from the company pay period start
  // will not back-calculate
  return fromDate => { // moment object
    // no company pay period, or fromDate was not a moment obj
    if (!state.user || !state.user.company || !state.user.company.pay_period_start_date || !fromDate.diff) {
      return null
    }

    const firstPayPeriod = tzMo(state.user.company.pay_period_start_date)
    if (fromDate.isBefore(firstPayPeriod)) { // no back calculation
      return null
    }

    let numDaysPassed = fromDate.diff(firstPayPeriod, 'days') // total number of days between first pay period and fromDate
    numDaysPassed = Math.floor(numDaysPassed / 14) * 14 // number of days that make up complete pay periods (will be a multiple of 14)

    // start of the day that the closest pay period to fromDate
    return firstPayPeriod.clone().add(numDaysPassed, 'days').startOf('day')
  }
}

export function isViewingLimitedTeam (state) {
  if (state.activeTeam && state.activeTeam.id && state.user.role !== 'admin') {
    const team = state.user.teams.find(ta => ta.team.id === state.activeTeam.id)
    return !team || team.access_level === 'limited'
  }
  return false
}

export function sidebarState ({ isAsideExpanded, isAsideMobileExpanded }) {
  return `${isAsideExpanded}-${isAsideMobileExpanded}`
}

export function licensesAvailable (state) {
  return state.user?.company?.licenses_available || 0
}
