/*
  functions to help with manipulation of data
*/
function nameSort (a, b) {
  return standardSort(a, b, 'name')
}

function standardSort (a, b, field = 'id', direction = 'asc') {
  const flip = direction === 'asc' ? -1 : 1
  if (a[field] && b[field]) {
    if (typeof a[field] === 'string') {
      if (typeof b[field] !== 'string') {
        // a is a string, b is not null non-string
        // we'll say a is first
        return -1 * flip
      }
      if (a[field].toLowerCase() > b[field].toLowerCase()) {
        return -1 * flip
      } else if (a[field].toLowerCase() < b[field].toLowerCase()) {
        return 1 * flip
      } else {
        return 0
      }
    } else {
      if (typeof b[field] === 'string') {
        // reverse of above, b is a string, a is not null non-string
        // we'll say b is first
        return 1 * flip
      }
      if (a[field] > b[field]) {
        return -1 * flip
      } else if (a[field] < b[field]) {
        return 1 * flip
      } else {
        return 0
      }
    }
  } else if (a[field]) {
    return -1 * flip
  } else if (b[field]) {
    return 1 * flip
  } else {
    return 0
  }
}

function multiSort (a, b, patterns) {
  for (let i = 0; i < patterns.length; i++) {
    let field = patterns[i]
    let dir = 'asc'
    if (Array.isArray(patterns[i])) {
      [field, dir] = patterns[i]
    }
    // if these fields are not equal sort by this pattern
    if (a[field] !== b[field]) {
      return standardSort(a, b, field, dir)
    }
    // else look for next pattern
  }
  // these are equal for all patterns
  return 0
}

function parseVSV (responseResult) {
  // split response result into rows, establish header. return empty array if empty string is passed.
  const arr = responseResult.vsv ? responseResult.vsv.split('?`@') : []
  const header = responseResult.header

  // iterate through each row, returns a list of json objects
  return arr.map(row => {
    // split rows into an array of items
    row = row.split('!`@')

    // the output object to be added to the final array of objects
    const out = {}

    // iterate through the header and convert the row into an object based on header items
    for (let i = header.length - 1; i >= 0; i--) {
      // if the row is 'null' as a string, set it to js null, saves us from having to do typecasting unnecessarily
      if (row[i] === 'null') {
        out[header[i][0]] = null
      } else if (header[i][1] === 'integer') { // if the value is not null (see above) and should be cast to int
        out[header[i][0]] = parseInt(row[i])
      } else if (header[i][1] === 'float') { // same as above but with float
        out[header[i][0]] = parseFloat(row[i])
      } else if (['json', 'array', 'object'].includes(header[i][1])) { // same as above but with json
        out[header[i][0]] = JSON.parse(row[i])
      } else { // must be a string
        out[header[i][0]] = row[i]
      }
    }

    return out
  })
}

function integrationToMetric (stat = 'primary_metric') {
  const statMap = {
    primary_metric: 'Edits',
    secondary_metric: 'Events',
    primary: 'Edits',
    secondary: 'Events',
    fl: 'edit',
    rate: 'Edits/hour',
    hours: 'Hours'
  }
  return statMap[stat]
}

/*
  maps record metrics into { metric-id: metric } for
  {
    arc_events
  }
  can pass singular record for singular mapping if needed
*/
function metricMapping (records) {
  const mapItem = (record, map, item) => {
    const { avatar, first_name, last_name, user_id } = record
    map[item.id] = Object.assign({}, { ...item }, { avatar, first_name, last_name, user_id })
    return map
  }

  const reduceRecord = (mapping, record) => {
    // merge id mappings from all records based on their metric types
    if (record.metric_name === 'arc' && Array.isArray(record.cached_metrics)) {
      Object.assign(mapping.arc_events, record.cached_metrics.reduce((emap, e) => {
        e.simpleType = e.type.includes(':') ? e.type.slice(0, e.type.indexOf(':')) : e.type
        return mapItem(record, emap, e)
      }, {}))
    }
    return mapping
  }

  const baseMapping = { arc_events: {} }
  if (!Array.isArray(records)) {
    if (records && records.id) { // this is a singular record
      return reduceRecord(baseMapping, records)
    }
  }
  return records.reduce((mapping, record) => reduceRecord(mapping, record), baseMapping)
}

function titleCase (text) {
  if (!text || !text.slice) {
    return ''
  }
  return text.slice(0, 1).toUpperCase() + text.slice(1)
}

function dashReplace (text) {
  if (!text || !text.replace) {
    return ''
  }
  return text.replace(/-/g, ' ')
}

function addS (arrayOrNumber) {
  if (Array.isArray(arrayOrNumber)) {
    return arrayOrNumber.length === 1 ? '' : 's'
  } else {
    return arrayOrNumber === 1 ? '' : 's'
  }
}

// joins a list of strings in a way that is human readable
// in whitespace: pre div's we might need to split newlines
// use newlineSplit = 3 to split every 3 elements for example
function listJoin (arr, newlineSplit = 0) {
  if (!arr?.length) {
    return ''
  } else if (arr.length <= 2) {
    return arr.join(' and ')
  } else {
    if (newlineSplit) {
      return arr.reduce((text, el, index) => {
        let elText = index ? ', ' : ''
        // this is the last index, write up el and el2
        if (index === arr.length - 1) {
          elText += 'and ' + el
        } else if (index && index % newlineSplit === 0) {
          // this is where the line splits
          elText += '\n' + el
        } else { // this is an intermediate element
          elText += el
        }
        return text + elText
      }, '')
    } else {
      return arr.slice(0, -1).join(', ') + ', and ' + arr[arr.length - 1]
    }
  }
}

function constrain (target, min = 0, max = Number.MAX_SAFE_INTEGER) {
  return Math.max(min, Math.min(target, max))
}

function sanitizedRegex (str, regParams = 'gi') {
  // fix up special characters that can mess up regular expression searching
  // this will prepend backslashes to each matched characters
  const sanitizedInput = str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  return new RegExp(sanitizedInput, regParams)
}

export {
  nameSort,
  standardSort,
  multiSort,
  parseVSV,
  integrationToMetric,
  metricMapping,
  titleCase,
  dashReplace,
  addS,
  listJoin,
  constrain,
  sanitizedRegex
}
