import io from 'socket.io-client'

const Socket = {
  install (Vue) {
    // creates a instance method that can be used inside of a Vue component
    const sock = {
      instantiatedNamespaces: [], // array of strings of the named of instantiated namespaces
      /**
       * Creates/opens socket and saves it as a first class member of this obj.
       * @param {string} namespace - The socket namespace getting passed to the server. Must not be already loaded and must not be a property of this object.
       * @param {Object} options - socket.io.js init options.
       */
      init (namespace, options = {}) {
        if (namespace in this) { // includes inherited properties
          throw Error('Invalid namespace; object already has that property.')
        }

        // instantiate socket connection, add to plugin, add to registry of loaded sockets
        // socketio handles this in a very weird way...you put in the base url where you server is, then a slash, then your
        // namespace in as the first argument. then you pass the actual path where the socket can be found as a path option.
        // behind the scenes i guess socks strips out the namespace and adds the base url and the path vars together...?
        let extraPath = ''
        if (Vue.axios.defaults.baseURL.length) {
          extraPath = new URL(Vue.axios.defaults.baseURL).pathname // turns chrono.cards/dashboard into just /dashboard
        }
        this[namespace] = io(`${window.location.origin}/${namespace}`, { ...{ path: `${extraPath}/api/socket.io` }, ...options })
        this.instantiatedNamespaces.push(namespace)

        // basic lifecycle hooks for the socket, can be overwritten by calling this.$socket.<namespace>.on(...) in the app
        this[namespace].on('connect', () => {
          console.log(`[SOCKET] ${namespace} connected: ${this[namespace].connected}`)
          window.vm.$store.commit('connectSocket', namespace)
        })
        this[namespace].on('disconnect', () => {
          console.log(`[SOCKET] ${namespace} connected: ${this[namespace].connected}`)
          window.vm.$store.commit('disconnectSocket', namespace)
        })

        // send the loaded socket back.
        return this[namespace]
      },
      /**
       * Disconnects socket, destroys socket.io instance, and removes namespace string from registry of instantiated namespaces.
       * @param {string} namespace - The socket namespace to destroy.
       */
      destroy (namespace) {
        if (!Object.prototype.hasOwnProperty.call(this, namespace)) { // only inclues things we've set, not inherited properties
          throw Error(`Namespace "${namespace}" could not be found.`)
        }

        // there's no harm in trying to disconnect regardless of connection status, so disconnect anyway for good measure
        if (this[namespace]) {
          this[namespace].disconnect()
        }

        // delete io instance from this object, then remove from registry
        delete this[namespace]
        this.instantiatedNamespaces = this.instantiatedNamespaces.filter(x => x !== namespace)
      },
      /**
       * Calls the destroy method on every namespace in the registry.
       */
      destroyAll () {
        this.instantiatedNamespaces.forEach(x => this.destroy(x))
      }
    }

    Vue.prototype.$socket = sock
  }
}

export default Socket
