import store from '@/store'
import moment from 'moment'

/**
 * @typedef {Object} Session
 * @property {string} name - DATE, STARTTIME - ENDTIME
 * @property {string} project - name of the project this came from
 * @property {number} hours - calculated time between events in session as decimal
 * @property {number} events - count of events (same as arcEvents.length)
 * @property {number} edits - count of events with type edit
 * @property {Array} arcEvents - original events grouped into this session
 */

/**
 * @typedef {Object} ProjectGroup
 * @property {string} project - the name of the project
 * @property {number} hours - calculated time between events as decimal
 * @property {number} events - count of events in all sessions
 * @property {number} edits - count of edits in all sessions
 * @property {Session[]} sessions - an array of sessions
 * @property {number} id
 */

/**
 * @typedef {Object} UserGroup
 * @property {number} hours
 * @property {number} edits
 * @property {number} events
 * @property {Array} arcEvents
 */

/**
 * @typedef {Object} UserProjectGroup
 * @property {number} userID
 * @property {ProjectGroup[]} projectSessions: []
 * @property {UserGroup[]} userSessions: []
 * @property {number} projectSessionCount: 0
 * @property {number} userSessionCount: 0
 * @property {number} edits: 0
 * @property {number} events: 0
 * @property {number} projectHours: 0
 * @property {number} userHours: 0
 */

/**
 * group arc events by project into sessions.
 *
 * @param {Array} events arcEvents that have had their fields parsed by fixFields (e.g. projects array parsed out of payload and eventDatetime is a moment object)
 * @param {number} session_timeout the maximum duration between arc events to be considered within the same session. someday may come from a company setting
 * @return {UserProjectGroup[]} an array of project groups
 */
export function groupArcProjects (events, session_timeout = 15) {
  const userProjectEvents = {} // user_id: { project_id: [events] }
  const dateFormat = store.getters.dFormatSetting
  const timeFormat = store.getters.tFormatSetting
  // first separate all events by user and project
  // this can include the `null` project to allow counting of all events
  events.forEach(metric => {
    const user_id = metric.user_id
    const project = metric.project_name || metric.projects[0] || null
    userProjectEvents[user_id] = userProjectEvents[user_id] || {}
    userProjectEvents[user_id][project] = userProjectEvents[user_id][project] || []
    userProjectEvents[user_id][project].push(metric)
  })

  // sort each of the events WITHIN projects by event datetime
  Object.keys(userProjectEvents).forEach(userID => {
    Object.keys(userProjectEvents[userID]).forEach(projectName => {
      userProjectEvents[userID][projectName].sort((a, b) => a.eventDatetime - b.eventDatetime)
    })
  })

  // parse stats user by user
  const userProjectSessions = Object.keys(userProjectEvents).reduce((userSessionData, userID) => {
    // use the first event to attach some user info to the reduced object
    const firstEvent = userProjectEvents[userID][Object.keys(userProjectEvents[userID])[0]]?.[0]
    const name = (firstEvent?.first_name || '') + ' ' + (firstEvent?.last_name || '')

    // sessionHours are per-project, userHours are across any projects
    // variance between these numbers may occur when working on two projects at once, or frequently switching between projects
    const userSession = {
      userID,
      id: userID, // less table changes
      first_name: firstEvent?.first_name,
      last_name: firstEvent?.last_name,
      name, // easier sorting
      avatar: firstEvent?.avatar,
      portal_username: firstEvent?.portal_username,
      projectSessions: {}, // sessions grouped by { project_id: [{ sessionObjs }] }
      userSessions: [], // sessions grouped by user [{ sessionObjs }]
      projectSessionCount: 0, // Object.keys(projectSessions).length for easier table sorting
      userSessionCount: 0, // userSessions.length for easier table sorting if this is shown
      edits: 0,
      events: 0,
      projectHours: 0,
      userHours: 0
    }

    // combine all user events into a single array regardless of project to calculate user hours
    const userEvents = Object.keys(userProjectEvents[userID]).reduce((uEvents, projectID) => {
      uEvents.push(...userProjectEvents[userID][projectID])
      return uEvents
    }, []).sort((a, b) => a.eventDatetime - b.eventDatetime)

    // calculate user sessions by all events a user has
    let singleUserSession = { hours: 0, events: 0, edits: 0, arcEvents: [] } // placeholder for active user-session
    const completeUserSession = () => {
      userSession.userHours += singleUserSession.hours
      userSession.userSessions.push(singleUserSession)
      singleUserSession = { hours: 0, events: 0, edits: 0, arcEvents: [] }
    }
    // create user-sessions (analyst) with all events from the user
    // will include events without a project, and time between projects. this is a better estimate of "real time" spent working
    userEvents.forEach((evt, index) => {
      if (index < userEvents.length - 1) {
        const minutes = userEvents[index + 1].eventDatetime.diff(evt.eventDatetime) / 1000 / 60
        if (Math.floor(minutes) <= session_timeout) {
          singleUserSession.hours += minutes / 60
          singleUserSession.arcEvents.push(userEvents[index])
        } else {
          completeUserSession()
        }
      } else if (index === userEvents.length - 1) {
        completeUserSession()
      }
    })

    // then create project sessions by iterating through this user's projects
    // this is time within a specific project only. having multiple projects open can result in much greater time than "real time"
    // switching between projects while still working within the minimum session time can lead to a much lower time than "real time"
    userSession.projectSessions = Object.keys(userProjectEvents[userID]).reduce((sessionGroups, project, id) => {
      const blankSession = () => ({ project, hours: 0, events: 0, edits: 0, arcEvents: [] })
      sessionGroups[project] = { project, hours: 0, events: 0, edits: 0, sessions: [], id }
      let session = blankSession()
      const completeSession = () => {
        userSession.projectHours += session.hours
        sessionGroups[project].hours += session.hours
        sessionGroups[project].events += session.events
        sessionGroups[project].edits += session.edits
        const date = session.arcEvents[0].eventDatetime.format(dateFormat)
        const start = session.arcEvents[0].eventDatetime.format(timeFormat)
        const end = session.arcEvents[session.arcEvents.length - 1].eventDatetime.format(timeFormat)
        session.name = `${date}, ${start} - ${end}`
        sessionGroups[project].sessions.push(session)
        session = blankSession()
      }
      userProjectEvents[userID][project].forEach((evt, index) => {
        session.events += 1
        userSession.events += 1
        session.arcEvents.push(evt)
        if (evt.type.includes('edit-')) {
          session.edits += 1
          userSession.edits += 1
        }
        if (index < userProjectEvents[userID][project].length - 1) {
          // consider dividing this at the end to avoid continuous rounding errors
          const minutes = userProjectEvents[userID][project][index + 1].eventDatetime.diff(evt.eventDatetime) / 1000 / 60
          if (Math.floor(minutes) <= session_timeout) {
            session.hours += minutes / 60
          } else {
            completeSession()
          }
        } else if (session.events && index === userProjectEvents[userID][project].length - 1) { // close off any sessions that have events when we hit last index
          completeSession()
        }
      })
      return sessionGroups
    }, {})

    // remove null project sessions, we don't want to display them, but we did want to count the hours/events
    // we can remove this section if we want to display non project-related sessions specifically
    Object.keys(userSession.projectSessions).forEach(projectID => {
      // stringified projectID == 'null' is expected for when there was no project open for the events
      if (!projectID || projectID === 'null' || projectID === 'undefined') {
        delete userSession.projectSessions[projectID]
      }
    })

    // add the count of sessions as a variable for easier access in tables (to make sortable without custom sort func)
    userSession.userSessionCount = userSession.userSessions.length
    userSession.projectSessionCount = Object.keys(userSession.projectSessions).length
    userSessionData[userID] = userSession
    return userSessionData
  }, {})
  return Object.values(userProjectSessions)
}

/**
 * Takes sessions grouped by project and transforms them into sessions grouped by day (by project).
 * Overlapping projects may occur and we might need to handle this.
 * @param {UserProjectGroup[]} userGroups
 * @return {{ [date]: Session[] }} sessions grouped by day with their project name attached to each session (since they may be mixed projects now)
 */
export function dailySessions (userGroups) {
  const dayGroups = {}
  userGroups.forEach(user => {
    Object.values(user.projectSessions).forEach(project => {
      project.sessions.forEach(session => {
        if (session.arcEvents?.length) {
          const date = session.arcEvents[0].eventDatetime.format('YYYY-MM-DD')
          dayGroups[date] = dayGroups[date] || []
          dayGroups[date].push(Object.assign({}, session, { project: project.project }))
        }
      })
    })
  })

  // return dayGroups sorted by date
  return Object.keys(dayGroups).sort((a, b) => moment(a) - moment(b)).reduce((dg, date) => {
    dg[date] = dayGroups[date]
    return dg
  }, {})
}
