<template>
  <li :class="{'is-active':isDropdownActive}">
    <a
      v-touch="menuClick"
      @click="menuClick"
      :title="componentTitle"
      :active-class="componentActiveClass"
      :class="componentClass"
      :id="itemId"
      data-position="right"
    >
      <b-icon v-if="item.icon" :icon="item.icon" :class="{ 'has-update-mark' : item.updateMark }" custom-size="default"  />
      <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
      <div v-if="hasSubmenuIcon" class="submenu-icon">
        <b-icon :icon="submenuIcon" custom-size="default"/>
      </div>
    </a>
    <aside-menu-list
        v-if="hasDropdown"
        :menu="item.menu"
        :isSubmenuList="true"/>
  </li>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AsideMenuItem',
  props: {
    item: {
      type: Object,
      default: null
    },
    isSecondary: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isDropdownActive: false
    }
  },
  methods: {
    menuClick () {
      if (this.item.to) {
        this.$router.push(this.item.to).catch(() => {}) // this was popping off errors sometimes but I can't figure out why. just catch them it routes fine.
      } else if (this.item.href) {
        window.open(this.item.href)
      }
      this.$emit('menu-click', this.item)

      if (this.hasDropdown) {
        this.isDropdownActive = (!this.isDropdownActive)

        if (!this.isSecondary && !this.isAsideMobileExpanded) {
          this.$store.commit('asideStateToggle', true)
        }
      }
    }
  },
  computed: {
    hasSubmenuIcon () {
      return this.hasDropdown || this.item.menuSecondary
    },
    hasDropdown () {
      return !!this.item.menu
    },
    submenuIcon () {
      if (this.item.menuSecondary) {
        return 'chevron-right'
      }
      return (this.isDropdownActive) ? 'minus' : 'plus'
    },
    itemId () {
      return this.item.id ? this.item.id : null
    },
    componentTitle () {
      return !this.isAsideExpanded && this.item.label ? this.item.label : null
    },
    componentClass () {
      const c = {
        'has-icon': !!this.item.icon,
        'has-submenu-icon': this.hasSubmenuIcon
      }

      if (this.item.state) {
        c['is-state-' + this.item.state] = true
        c['is-hoverable'] = true
      }
      if ((this.asideActiveForcedKey && this.item.menuSecondaryKey && this.asideActiveForcedKey === this.item.menuSecondaryKey) || (this.item.to && this.item.to.name === this.$route.name)) {
        c['is-active'] = true
      }
      return c
    },
    componentActiveClass () {
      if (this.asideActiveForcedKey) {
        return null
      }
      return 'is-active'
    },
    ...mapState([
      'asideActiveForcedKey',
      'isAsideExpanded'
    ])
  },
  watch: {
    isAsideExpanded (newValue) {
      if (!newValue) {
        this.isDropdownActive = false
      }
    }
  }
}
</script>
