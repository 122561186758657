import { downloadFile, dtFormat, hFormat } from '.'

function openError (message) {
  window.vm.$buefy.snackbar.open({
    message,
    type: 'is-danger'
  })
}

function removeSpecialChars (fields) {
  return fields.map(field => {
    return field && field.replace ? field.replace(/\r|\n|,|#/g, '') : field
  })
}

export function downloadMetricsData (data, title) {
  let csv = ''
  const headers = ['Name', 'Time', 'Type', 'Projects', 'Maps', 'Layers', 'Data Sources', 'Processing Time (ms)', 'Sketch Time (ms)']

  csv += headers.join(',') + '\r\n'
  if (!data || !data.length) {
    return openError('No data to export!')
  }

  data.forEach(metric => {
    let fields = [
      (metric.first_name || '') + ' ' + (metric.last_name || '')
    ]
    fields.push(metric.eventDatetime ? dtFormat(metric.eventDatetime, true) : 'N/A')
    fields.push(metric.simpleType.replace(/-/g, ' '))
    fields.push(metric.projects?.length ? metric.projects.join('; ') : '')
    fields.push(metric.maps?.length ? metric.maps.join('; ') : '')
    fields.push(metric.layers?.length ? metric.layers.join('; ') : '')
    fields.push(metric.dataSources?.length ? metric.dataSources.join('; ') : '')
    fields.push(metric.processingTime || '')
    fields.push(metric.sketchTime || '')
    fields = removeSpecialChars(fields)
    // single quotes with double quotes
    csv += `${fields.join(',')}\r\n`.replace(/"/g, '""')
  })
  return downloadFile(csv, `${title}.csv`)
}

export function downloadGroupedMetricsData (data, title) {
  let csv = ''
  const headers = ['Name', 'Edits', 'Events']
  csv += headers.join(',') + '\r\n'
  if (!data || !data.length) {
    return openError('No data to export!')
  }

  data.forEach(metric => {
    let fields = [
      (metric.first_name || '') + ' ' + (metric.last_name || '')
    ]
    fields.push(metric.primary)
    fields.push(metric.secondary)
    fields = removeSpecialChars(fields)
    csv += `${fields.join(',')}\r\n`.replace(/"/g, '""')
  })

  return downloadFile(csv, `${title}.csv`, 'text/csv')
}

export function downloadArcProjectData (data, title) {
  let csv = ''
  const headers = ['User', 'Project', 'Hours', 'Events', 'Edits']
  csv += headers.join(',') + '\r\n'
  if (!data?.length) {
    return openError('No data to export!')
  }

  const appendFields = fields => {
    fields = removeSpecialChars(fields)
    csv += `${fields.join(',')}\r\n`.replace(/"/g, '""')
  }

  data.forEach(user => {
    appendFields([
      user.name,
      '** Analyst hours total **',
      hFormat(user.userHours),
      user.events,
      user.edits
    ])
    Object.values(user.projectSessions).forEach(projectSession => {
      appendFields([
        user.name,
        projectSession.project,
        hFormat(projectSession.hours),
        projectSession.events,
        projectSession.edits
      ])
    })
  })

  return downloadFile(csv, `${title}.csv`)
}
