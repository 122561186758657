import Vue from 'vue'

export function setTodayMetric ({ state, commit, dispatch, getters }) {
  // no payload. update to the active navbar metric
  // sets to the users setting, or the first available metric, or nothing
  // depending on permission
  if (!state.user) {
    return
  }
  const settings = state.user.settings || {}
  const metric = state.todayMetricType
  if (metric && getters.allowedMetrics.includes(metric)) {
    commit('basic', { key: 'todayMetricType', value: metric })
  } else if (settings.navbar_metric && getters.allowedMetrics.includes(settings.navbar_metric)) {
    // either there is no current metric or it was not allowed
    commit('basic', { key: 'todayMetricType', value: settings.navbar_metric })
  } else if (getters.allowedMetrics.length) {
    // you don't have settings either or that too was not allowed
    commit('basic', { key: 'todayMetricType', value: getters.allowedMetrics[0] })
    dispatch('updateSettings', { navbar_metric: getters.allowedMetrics[0] })
  } else { // you don't have any allowed metrics
    commit('basic', { key: 'todayMetricType', value: null })
  }
}

export function updateSettings ({ state, commit }, payload) { // self only
  if (!state.user || !state.user.id) {
    // make sure we don't try and save settings when you're not logged in for any reason
    return Promise.resolve(false)
  }
  const mergedSettings = Object.assign({}, state.user.settings, payload)
  return Vue.axios.put('/api/sync-settings', mergedSettings).then(response => {
    if (response && response.data.result) {
      commit('setUser', response.data.result)
      return Promise.resolve(response.data.result)
    } else {
      return Promise.resolve()
    }
  })
}

export function toggleSidebar ({ state, commit, dispatch }) {
  const originalPosition = state.isAsideExpanded
  commit('asideStateToggle')
  return new Promise((resolve, reject) => { // update setting if you're not spamming it back/forth or maybe something was being initialized
    setTimeout(() => {
      if (state.isAsideExpanded !== originalPosition && (state.user && state.user.id)) {
        return dispatch('updateSettings', { asideOpen: state.isAsideExpanded }).then(resolve).catch(reject)
      } else {
        resolve()
      }
    }, 250)
  })
}

export function updateGeolocation ({ state, dispatch }, retries = 3) {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      const promises = [] // may be empty if not possible
      if (navigator.permissions) {
        // second answer; about permissions api
        // https://stackoverflow.com/questions/6092400/is-there-a-way-to-check-if-geolocation-has-been-declined-with-javascript
        // I have location permission denied by windows on my laptop (at a windows level)
        // this section prevents it from showing "chrono.cards wants to use your location, but first you need to go to settings and turn on locations"
        // every single time I refreshed the page and vuex would start up
        // only works in chrome, firefox, opera (untested)
        promises.push(navigator.permissions.query({ name: 'geolocation' }).then(perm => {
          if (perm.state === 'denied') {
            // reject this saying permission was denied before ever requesting
            return Promise.reject({ code: 1 }) // eslint-disable-line prefer-promise-reject-errors
          }
          return Promise.resolve()
        }))
      }
      Promise.all(promises).then(() => {
        navigator.geolocation.getCurrentPosition(position => {
          Vue.set(state, 'lat', position.coords.latitude)
          Vue.set(state, 'lon', position.coords.longitude)
          resolve({ lat: this.lat, lon: this.lon })
        }, reject, { timeout: 5000 })
      }).catch(reject)
    } else {
      reject(Error('Geolocation is not supproted by this browser'))
    }
  }).catch(e => { // disabled geolocation was really upset here
    if (e && e.code === 1) { // denied no retry
      Vue.set(state, 'locationDenied', true)
      return Promise.resolve(e)
    }
    if (retries && !Number.isNaN(retries) && retries > 0) {
      console.log('Retrying geolocation')
      return dispatch('updateGeolocation', retries - 1)
    }
    return Promise.resolve(e)
  }).finally(response => {
    Vue.set(state, 'locationRequestTried', true)
    return Promise.resolve(response) // pass this out still
  })
}
