<template>
  <div class="image is-user-avatar" :class="[wrapperClass, {'clickable': canClickProfile }]" v-on="clickBinding">
    <img v-if="user.avatar && !fallbackRequired" :src="avatarUrl" class="is-rounded" ref="avatarImage" loading="lazy"/>
    <div v-else
      class="initials-placeholder"
      :style="userColor"
      :class="{'is-invisible': !user.avatar && !user.id && !user.first_name && !user.last_name}"
    >
      <span style="position: relative; top: 1%">
        {{ initials }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    user: { // must contain avatar, portal_username, first_name, last_name, id
      type: Object,
      required: true,
      validator (value) {
        return (
          (typeof value.avatar === 'string' || value.avatar === null) &&
          typeof value.portal_username === 'string' &&
          typeof value.first_name === 'string' &&
          typeof value.last_name === 'string' &&
          (value.id !== null || value.id !== undefined)
        )
      }
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    profileClick: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fallbackRequired: false
    }
  },
  mounted () {
    // set up the image fallback on 404
    if (this.$refs.avatarImage) {
      this.$refs.avatarImage.onerror = () => {
        this.fallbackRequired = true
      }
    }
  },
  methods: {
    onClick (event) {
      if (this.profileClick) {
        // we can go directly to the user's trends; if you can click an avatar here you have access to view this user's trends
        this.$router.push({ name: 'users', params: { userId: this.user.id, tab: 'trends' } })
      } else {
        this.$emit('click', event)
      }
    }
  },
  computed: {
    initials () {
      const { first_name, last_name } = this?.user || {}
      const firstUpper = name => name.slice(0, 1).toUpperCase()
      if (first_name?.length && last_name?.length) {
        return firstUpper(first_name) + firstUpper(last_name)
      } else if (first_name?.length) {
        return firstUpper(first_name)
      } else if (last_name?.length) {
        return firstUpper(last_name)
      } else {
        return '?'
      }
    },
    avatarUrl () {
      if (this.user?.avatar) { // avatar is a string or null
        if (this.user.avatar.startsWith('data:image/')) { // we're probably not logged in and loading avatar from localstorage
          return this.user.avatar // avatar is the complete dataURL needed to render the avatar.
        } else { // this is the URL used to fetch the avatar if you have auth (might come from browser cache)
          return `/api/images/avatar?portal_username=${this.user.portal_username}&avatar=${this.user.avatar}`
        }
      }
      return '' // no avatar means no url, but this should never get called since avatarUrl is only rendered if user.avatar
    },
    canClickProfile () {
      return ( // you are:
        this.$auth.is('admin') || // admin
        (this.$auth.user() && parseInt(this.user?.id) === this.$auth.user().id) || // self
        (this.$store.state.activeTeam && this.$auth.hasManagerAccess(this.$store.state.activeTeam.id)) // or this team's manager,
      ) &&
      !['my-report', 'profile'].includes(this.$route.name) && // but you're not on your own route already
      this.$route?.params?.userId !== this.user?.id // and you're not already on the user's page you were going to click to
    },
    userColor () {
      const colors = ['011627', '27ab9e', '7f7eff', 'a61626', '054a29', '688e26', '8f3985', '626c66', 'e8ac3c', 'd98f4a']
      const idNum = parseInt(this.user?.id) || parseInt(Math.random() * 250) // either their id or some random integer
      return `background-color: #${colors[idNum % colors.length]}`
    },
    clickBinding () {
      if (this.profileClick) {
        // can't use click.stop modifier this way (I think there is a way, but docs changed and idk syntax)
        return { click: evt => { evt.stopPropagation(); this.onClick(evt) } }
      } else {
        return {} // don't bind click if we don't have the profile click option on
      }
    }
  }
}
</script>
<style>
.image.is-user-avatar .initials-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  height: 100%;
  width: 100%;
  background-color: #209cee;
  border-radius: 100%;
  color: white;
  font-weight: bold;
}

section.hero.is-hero-bar.is-main-hero .initials-placeholder {
  width: 10rem;
  height: 10rem;
  font-size: 5rem;
}
</style>
