/**
  @param {Object} arcEvent the arc event WITH PAYLOAD
  @returns {String} human-readable, formatted python call for arcpy

  uses the type of the arc event + it's payload to generate an arcpy call.
  used mostly for generating ipy notebooks.
*/
export function arcEventToPythonCall (arcEvent) {
  // if hydration never completed, we have neither the tool name nor the params. short-circuit and be sad.
  if (!arcEvent.payload.items[0]?.extraParameters?.tool) {
    return `# the ${arcEvent.payload.items[0]?.name} tool was ran, but we never received the parameters needed to create an arcpy call :(`
  }

  // we're cutting corners here and assuming that each payload will only have one item.
  // some events might have no params at all, so ` || {param: []}` will return a empty array by default.
  const paramArray = (arcEvent.payload.items[0].extraParameters?.params || { param: [] }).param.filter(
    // either looking for input params (!output) or non-derived/user-entered output params
    param => (!param.output || (param.output && !param.derived)) && param.text !== null
  )
  // format and stringify the params. only add indent spacing if more than 1 param.
  const spacing = paramArray.length > 1 ? '\n    ' : ''
  const paramString = paramArray.map(param => `${spacing}${param.name}="${param.text.replaceAll('"', '\\"')}"`).join(',')

  // get the tool's function signature and generate the call
  const tool = arcEvent.payload.items[0].extraParameters.tool
  const closingParenAlign = paramArray.length > 1 ? '\n' : '' // put on a new line only if more than 1 param
  const call = `arcpy.${tool.toolboxalias}.${tool.name}(${paramString}${closingParenAlign})`

  return call
}
