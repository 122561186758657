import axios from 'axios'
import Cookie from 'js-cookie'
import { NotificationProgrammatic as Notification } from 'buefy'

axios.defaults.baseURL = '' // this is not a default and must be set as '' unless we change how we access /static/ and socks.

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // add CSRF token to every request
  let token = ''
  if (config.url.includes('/api/auth/refresh')) {
    token = Cookie.get('csrf_refresh_token')
  } else {
    token = Cookie.get('csrf_access_token')
  }
  config.headers['X-CSRF-TOKEN'] = token

  // Do something else before request is sent
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

/*
When a 401 kicks off (unauthenticated)
- start a refresh request setting the refreshPromise for others to use to chain
- if refresh promise succeeds, we have new credentials. retry the request
- if refresh promise fails, logout
- unset refreshPromise
if another 401 kicks off during this time
- return the current refresh promise with a .then of
- if success: we have new credentials. retry the request
- if fail: return error, logout will happen from original refresh catch of failure

also note the use of refreshPromise.then from separate requests will be fired concurrently
this means that we get the data as fast as the first refresh comes back and we retry our own original request
*/
let refreshPromise = null

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  if (response && [200, 201].includes(response.status) && response.data.message) {
    Notification.open({
      message: response.data.message,
      type: 'is-success',
      duration: response.data.message_duration || 2000
    })
  }
  return response
}, function (error) {
  // try to refresh the token if it came back as unauthorized
  const originalRequest = error.config
  if (axios.isCancel(error)) {
    return Promise.resolve(error) // we asked for cancellation
  }
  // it seems that no error.response or status is a network error
  // https://github.com/axios/axios/issues/383
  if (!error || !error.response || !error.response.status || !error.response.data) {
    Notification.open({
      message: 'Network error! Please check your connection.',
      type: 'is-danger',
      duration: 5500
    })
    return Promise.reject(error)
  }

  // this was an error to refresh, no errors here are accepted, auth expired
  if (originalRequest.url.includes('/api/auth/refresh')) {
    Notification.open({
      message: 'Your session has expired; please log in again.',
      type: 'is-warning'
    })
    return vm.$auth.logout().finally(() => { // eslint-disable-line
      return Promise.reject(error) // pass out whatever set this off instead of logout response
    })
  }

  if (error && error.response && error.response.status === 401) { // if we came back as unauthorized
    if (!refreshPromise) { // we haven't started a refresh yet
      // refresh credentials. then retry our original request
      // allow other requests to see this one and use it if they get 401 also
      refreshPromise = axios.post('/api/auth/refresh').then(() => {
        // auth success. retry *this* request
        return axios(originalRequest)
      }).finally(() => {
        refreshPromise = null // make sure we clear out this for later
      })
      return refreshPromise
    } else { // a refresh exists. just wait for that one to finish up and retry our request
      // we need to use finally in case an intermediate request failed
      // so we'll wrap this in it's own promise to chain properly
      // and we must check you're still logged in since this is in a .finally, refresh may have failed
      return new Promise((resolve, reject) => {
        refreshPromise.finally(() => {
          if ('vm' in window && vm.$auth.isLoggedIn()) { // eslint-disable-line
            return axios(originalRequest).then(resolve).catch(reject)
          } else {
            return reject(Error('Could not retry authenticated request after being logged out'))
          }
        })
      })
    }
  }

  // two tries at raising relevant errors before raising a generic whoops
  if (error && error.response && [400, 405, 403].includes(error.response.status) && error.response.data.message) {
    Notification.open({
      message: error.response.data.message,
      type: 'is-danger',
      duration: error.response.data.message_duration || 2000
    })
    // if our portal session expired, we're getting booted from chronocards and need to reauthenticate.
    if (error.response.data?.action === 'reauth' && error.response.data?.url) {
      return vm.$auth.logout().finally(() => { // eslint-disable-line
        window.location.assign(error.response.data.url)
        return Promise.reject(error)
      })
    }
  } else if (error && error.response && error.response.status === 422 && error.response.data) {
    // invalid signature will match all above, but we want to force a logout if that's the case
    // this should only happen if app secret keys are changed or someone tampered with their token
    if (error.response.data.msg && error.response.data.msg === 'Signature verification failed') {
      Notification.open({
        message: 'Your session has expired; please log in again.',
        type: 'is-warning'
      })
      return vm.$auth.logout().finally(() => { // eslint-disable-line
        return Promise.reject(error)
      })
    } else { // add a descriptive error based on the content from the message
      for (const [key, value] of Object.entries(error.response.data)) {
        Notification.open({
          message: `${key.replace(/_/g, ' ')} field: ${Array.isArray(value) ? value[0] : value}`,
          type: 'is-danger',
          duration: error.response.data.message_duration || 2000
        })
      }
    }
  } else {
    Notification.open({
      message: "ERROR 999999: It seems we're having some trouble with your request. Please try again in a bit or contact support to get this sorted out.",
      type: 'is-danger',
      indefinite: true
    })
  }

  // an error was thrown that included "logout: true"
  // and it did not come from /api/logout
  if (error && error.response && error.response.data && error.response.data.logout && !originalRequest.url.includes('/logout')) {
    return axios.post('/api/auth/logout').then(() => {
      if ('vm' in window && vm.$route.name !== 'login') { // eslint-disable-line
        vm.$router.push({ name: 'login' }) // eslint-disable-line
      }
      return Promise.reject(error)
    })
  }
  return Promise.reject(error)
})

export default axios
