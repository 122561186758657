<template>
  <b-modal :active="active" @close="onClose" :width="width" has-modal-card :id="modalId" :can-cancel="canCancel" :scroll="scroll">
    <div class="modal-card wide-card" :class="cardClass">
      <header class="modal-card-head" :class="headClass" v-if="title || $slots.title || $slots.icon">
        <p class="modal-card-title" :class="titleClass">
          <slot name="title">{{title}}</slot>
        </p>
        <slot name="icon">
        </slot>
      </header>
      <section class="modal-card-body" :class="[{'next-prev': listCard, 'rounded-bottom': !$slots.footer, 'rounded-top': !title && !$slots.title && !$slots.icon}, bodyClass]">
        <div class="modal-body-container">
          <div class="modal-card-previous" v-if="listCard" @click="$emit('prev')">
            <b-icon size="is-large" icon="chevron-left" />
          </div>
          <div class="list-card-content" :class="contentClass">
            <slot></slot>
          </div>
          <div class="modal-card-next" v-if="listCard" @click="$emit('next')">
            <b-icon size="is-large" icon="chevron-right" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot content-centered" :class="footerClass" v-if="$slots.footer">
        <slot name="footer"></slot>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalCard',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    listCard: { // show next/prev buttons
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 1200
    },
    modalId: {
      type: [String, Object, Array]
    },
    cardClass: {
      type: [String, Object, Array]
    },
    headClass: {
      type: [String, Object, Array]
    },
    bodyClass: {
      type: [String, Object, Array]
    },
    contentClass: {
      type: [String, Object, Array]
    },
    footerClass: {
      type: [String, Object, Array]
    },
    titleClass: {
      type: [String, Object, Array]
    },
    canCancel: {
      type: [Boolean, Array],
      default: () => (['escape', 'x', 'outside'])
    },
    scroll: {
      type: String,
      default: 'clip'
    }
  },
  methods: {
    onClose () {
      this.$emit('update:active', false)
      this.$emit('close')
    }
  }
}
</script>

<style>
  .modal-card {
    overflow: visible;
  }
  .modal-card-body.next-prev {
    margin: 0;
    padding: 0;
  }
  .modal-card-body.next-prev .modal-body-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background-color: gainsboro;
    margin: 0;
    padding: 0
  }

  .modal-card-body.next-prev .modal-body-container .modal-card-previous,
  .modal-card-body.next-prev .modal-body-container .modal-card-next {
    flex-basis: 24px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .modal-card-body.next-prev .modal-body-container .modal-card-previous:hover,
  .modal-card-body.next-prev .modal-body-container .modal-card-next:hover {
    color: #209cee;
  }

  .modal-card-body.next-prev .modal-body-container .list-card-content {
    width: 100%;
    padding: 24px;
    margin-bottom: 0;
    background-color: white;
  }

  .modal-card-body.next-prev .modal-body-container .list-card-content .column {
    padding-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    .modal-card-body.next-prev .modal-body-container .modal-card-previous,
    .modal-card-body.next-prev .modal-body-container .modal-card-next {
      padding: 0;
    }
    .modal-card-body.next-prev .modal-body-container .modal-card-previous,
    .modal-card-body.next-prev .modal-body-container .modal-card-previous .icon.is-large,
    .modal-card-body.next-prev .modal-body-container .modal-card-previous .icon.is-large i,
    .modal-card-body.next-prev .modal-body-container .modal-card-next,
    .modal-card-body.next-prev .modal-body-container .modal-card-next .icon.is-large,
    .modal-card-body.next-prev .modal-body-container .modal-card-next .icon.is-large i {
      width: 36px;
    }

    .modal-card-body.next-prev .modal-body-container .modal-card-previous .icon.is-large i::before,
    .modal-card-body.next-prev .modal-body-container .modal-card-next .icon.is-large i::before {
      font-size: 36px;
    }
  }
</style>
