/* Styles */
import '@/scss/buefy-override.scss'
import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'
import '@/css/chrono.css'
import '@/css/wfcal.css'
import '@/css/table.css'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import Vue2TouchEvents from 'vue2-touch-events'
import { VueDc } from 'vue-dcjs'

/* Router & Store & Network */
import router from './router'
import store from './store'
import axios from './network'
import VueAxios from 'vue-axios'

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

/* Vue. Component in recursion */
import AsideMenuList from '@/components/aside/AsideMenuList'

// put on some socks
import Socket from './plugins/Socket'

// bring in auth for client
import Auth from './plugins/Auth'

/* error and analytics tracking */
import Rollbar from 'vue-rollbar'
import VueGtag from 'vue-gtag'

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('asideMobileStateToggle', false)
  store.commit('overlayToggle', false)
  store.commit('asideActiveForcedKeyToggle', null)
})

Vue.config.productionTip = false

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList)

// https://github.com/buefy/buefy/issues/3433
Vue.use(Buefy, {
  // just show all the notifications at once instead of slowly waiting (all types use same setting)
  defaultNoticeQueue: false,
  // set all notification types default position to the top right
  defaultSnackbarPosition: 'is-top-right',
  defaultToastPosition: 'is-top-right',
  defaultNotificationPosition: 'is-top-right',
  // set the b-field label-position to 'on-border'
  defaultFieldLabelPosition: 'on-border'
})

Vue.use(Vue2TouchEvents)

Vue.use(VueAxios, axios)

Vue.use(Socket)

Vue.use(Auth, router, store)

if (process.env.NODE_ENV === 'production') {
  // base config defined here
  // user added to payload in plugins/auth.js
  Vue.use(Rollbar, {
    accessToken: '30095e4f25984e149d90645f6966f91c', // post_client_item token
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    environment: `merge-req-${process.env.CI_MERGE_REQUEST_IID || 'unknown'}`, // might not be set by ci/cd?
    ignoredMessages: [
      "SyntaxError: Unexpected token '<'",
      "SyntaxError: Expected expression, got '<'",
      "Uncaught SyntaxError: Unexpected token '<'",
      "Unexpected token '<'",
      'ChunkLoadError: Loading chunk',
      'Error: Loading CSS chunk',
      'error getting `reason` from event' // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onunhandledrejection
      // looks like unhandled rejections should come across with `event.reason` and in some events (network error?) it is undefined
    ],
    payload: {
      client: {
        javascript: {
          code_version: process.env.VUE_APP_VERSION,
          source_map_enabled: true,
          guess_uncaught_frames: true
        }
      }
    }
  })

  Vue.config.errorHandler = (err, vm, info) => {
    console.error(err)
    console.log('store state:', vm.$store.state)
    console.log('store getters: ', vm.$store.getters)
    console.log('info: ', info)
    Vue.rollbar.error(err)
  }
}

Vue.use(VueGtag, {
  config: {
    id: 'UA-139335185-3'
  },
  enabled: process.env.NODE_ENV === 'production'
}, router)

Vue.use(VueDc, {
  resetSelector: chart => {
    try {
      return document.querySelector(chart.anchor()).closest('.card').querySelectorAll('.chart-reset-button')
    } catch (e) {
      console.error(e)
      return null
    }
  },
  titleRoundDigits: 1
})

Vue.prototype.VUE_APP_VERSION = process.env.VUE_APP_VERSION

window.vm = new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.documentElement.classList.remove('has-spinner-active')
  }
}).$mount('#app')
