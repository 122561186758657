<template>
  <div>
    <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top" :class="{'has-metrics': allowedMetrics.length}">
      <div class="navbar-brand" :class="{'has-company-logo': companyLogo}">
        <a @click.prevent="menuToggle" :title="toggleTooltip" class="navbar-item is-desktop-icon-only is-hidden-touch" id="desktop-aside-toggle">
          <b-icon :icon="menuToggleIcon" custom-size="mdi-24px has-height-auto"/>
        </a>
        <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile" id="mobile-aside-toggle">
          <b-icon :icon="menuToggleMobileIcon" custom-size="mdi-24px has-height-auto"/>
        </a>
        <div class="navbar-item no-left-space is-hidden-touch" v-if="user && user.company">
          <div class="control" v-if='companyLogo'>
            <img :src="companyLogo" style="width: 32px; height: 32px; margin-right: 8px; margin-bottom: -8px; max-height: 32px; object-fit: cover;" />
          </div>
          <div class="control" id="navbar-company-name">
            <b>{{ user.company.name }}</b>
          </div>
        </div>
        <div class="navbar-item no-left-space" :class="{'has-gear-icon': allowedMetrics.length > 1, 'has-metrics': allowedMetrics.length}" id="navbar-stats-area">
          <b-tooltip
            :active="!socketConnected"
            type="is-chrono"
            :label="disconnectedLabel"
            :multilined="true"
            position="is-bottom"
            id="disconnected-tooltip"
            style="display: contents;"
          >
          <div v-if="todayMetricType" class="control time-control" id="navbar-today-metric" :style="allowedMetrics.length > 1 ? 'flex-basis: 98px;' : ''">
            <div class="time-wrapper" :class="{'socket-disconnected': !socketConnected}" style="padding: 0 4px;">
              <div :key="`metrics-${updated}`">{{todayMetric()}}</div>
              <div class="metric-wrapper">{{integrationToMetric().toLowerCase()}} today</div>
            </div>
            <div v-if="allowedMetrics.length > 1" @click.stop="() => {/* don't navigate */}">
              <b-dropdown ref="navMetric" :value="todayMetricType">
                <div slot="trigger" style="width: 36px;">
                  <b-icon icon="settings" style="display: inline; margin: 0;" custom-size="is-default"/>
                  <b-icon icon="menu-down" size="is-small" style="display: inline;  margin: 0" />
                </div>
                <b-dropdown-item v-for="metric in allowedMetrics" :key="metric" :value="metric" @click.native.stop="changeActiveMetric(metric)">
                  {{integrationToMetric().toLowerCase()}}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          </b-tooltip>
        </div>
      </div>
      <div class="navbar-brand is-right">
        <div class="navbar-item navbar-item-menu-toggle is-hidden-desktop" style="padding-right: 0;">
          <a @click.prevent="menuNavBarToggle">
            <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
          </a>
        </div>
      </div>
      <div ref="menuNavBar" class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
        <div class="navbar-end">
          <nav-bar-menu class="has-divider has-user-avatar is-user-dropdown">
            <user-avatar v-if="user && user.id" :user="user" wrapperClass="is-32x32" />
            <div class="is-user-name">
              <span class="limit-username-length">{{ `${user.first_name} ${user.last_name}` }}</span>
              <b-tooltip v-if="timezoneIsNotDefault"
                  size="is-small"
                  id="timezone-alert"
                  :multilined="true"
                  type="is-danger"
                  :label="`You've set ChronoCards to use ${$store.getters.currentTimezoneSetting} as your profile's timezone. This is different than what your computer is currently using.`"
                  position="is-bottom"
                >
                  <b-icon icon="alert-circle-outline" custom-size="default" type="is-danger" style="height: 12px; position: relative; top: 2px;" />
                </b-tooltip>
            </div>

            <div slot="dropdown" class="navbar-dropdown">
              <a class="navbar-item" @click="goTo('profile')">
                <b-icon icon="account" custom-size="default"></b-icon>
                <span>My Profile</span>
              </a>
              <a class="clickable navbar-item is-hidden-desktop" @click="supportModalActive = true">
                <b-icon icon="help-circle-outline" custom-size="default" style="position: relative; top: 1px" />
                <span>Support</span>
              </a>
              <a class="navbar-item" @click="goTo('logout')">
                <b-icon icon="logout" custom-size="default"></b-icon>
                <span>Log Out</span>
              </a>
            </div>
          </nav-bar-menu>
          <div class="navbar-item has-divider clickable support-nav-item is-hidden-touch" style="display: flex" @click="supportModalActive = true">
            <b-icon icon="help-circle-outline" size="is-small" />
            <span class="is-hidden-desktop" style="padding-left: 16px;">Support</span>
          </div>
        </div>
      </div>
    </nav>
    <!-- this must be outside nav to break out and render on top of sidebar -->
    <support-modal style="z-index: 101" :active.sync="supportModalActive" />
  </div>
</template>

<script>
import SupportModal from '@/components/SupportModal'
import NavBarMenu from '@/components/frames/NavBarMenu'
import UserAvatar from '@/components/common/UserAvatar'
import { mapState, mapGetters } from 'vuex'
import { integrationToMetric } from '@/utils'
import moment from 'moment-timezone'

export default {
  name: 'NavBar',
  components: {
    NavBarMenu,
    UserAvatar,
    SupportModal
  },
  data () {
    return {
      isMenuNavBarActive: false,
      supportModalActive: false,
      updateInterval: null,
      updated: null, // re-key moving components so they update on demand
      cachedRoute: null,
      mousedownTimeout: null,
      mouseDownHandler: null,
      mouseUpHandler: null,
      isClickingNav: false,
      hovering: false,
      hoverSafeUpdated: 1
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    menuToggleIcon () {
      return this.isAsideExpanded ? 'backburger' : 'forwardburger'
    },
    toggleTooltip () {
      return this.isAsideExpanded ? 'Collapse' : 'Expand'
    },
    companyLogo () {
      return this.user?.company?.logo ? `/api/images/logo?logo=${this.user.company.logo}` : null
    },
    timezoneIsNotDefault () {
      return this.$store.getters.currentTimezoneSetting !== moment.tz.guess()
    },
    disconnectedLabel () {
      return 'There was a problem connecting to your information. This data may be incorrect.'
    },
    ...mapState([
      'isNavBarVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'user',
      'hasUpdates',
      'todayMetricType',
      'lastMetricSeen',
      'latestMetricItem'
    ]),
    ...mapGetters([
      'allowedMetrics',
      'todayMetric',
      'socketConnected'
    ])
  },
  methods: {
    integrationToMetric () {
      return integrationToMetric('primary_metric') || 'N/A'
    },
    menuToggle () {
      this.$store.dispatch('toggleSidebar')
    },
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    changeActiveMetric (metric) {
      this.$store.dispatch('updateSettings', { navbar_metric: metric })
      this.$store.commit('basic', { key: 'todayMetricType', value: metric })
    },
    goTo (name) {
      // prevent navigation duplicated
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
      this.isMenuNavBarActive = false
    }
  },
  beforeUpdate () {
    // for some reason `beforeRouteLeave` and `beforeRouteUpdate` don't fire on navbar
    // we'll use updated and check the path vs last path we saw
    if (this.isMenuNavBarActive && this.cachedRoute && this.cachedRoute !== this.$route.path) {
      this.isMenuNavBarActive = false
    }
    this.cachedRoute = this.$route.path
  }
}
</script>

<style scoped>
.control.time-control {
  cursor: pointer;
  flex-basis: 72px;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.control.time-control .time-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 72px;
  height: 100%;
  align-content: center;
  align-items: center;
}
.control.time-control:not(:first-of-type) .time-wrapper {
  border-left: 1px solid gainsboro;
}
.control.time-control .time-wrapper div {
  line-height: 1rem;
}
.control.time-control .time-wrapper .metric-wrapper {
  text-align: center;
  font-size: 0.9rem;
  line-height: 0.75rem;
}

.support-nav-item span.icon {
  margin-right: 0 !important;
}

.support-nav-item:hover {
  color: #3273dc;
  background-color: #fafafa;
}

@media screen and (max-width: 1023px) {
  .support-nav-item span.icon {
    margin-left: 16px;
    margin-top: 4px;
  }
  .navbar-brand.is-right .navbar-item.is-hidden-desktop:last-child {
    margin-right: 12px;
  }
  .limit-username-length {
    max-width: calc(100vw - 6rem) !important; /* just enough space for name and avatar and padding */
  }
}

@media screen and (max-width: 374px) {
  .control.time-control {
    flex-basis: 58px;
  }
  .control.time-control .time-wrapper,
  .control.time-control .time-wrapper div,
  .control.time-control .time-wrapper .metric-wrapper {
    font-size: 0.75rem;
  }
  #navbar-stats-area,
  #navbar-stats-area.has-gear-icon {
    min-width: 214px;
  }
}
</style>

<style>
  #navbar-main {
    height: 59px;
  }
  .icon.has-update-mark:after,
  .icon.has-chrono-mark:after {
    top: -2px;
    right: -2px;
  }

  nav.navbar .navbar-item .metric-wrapper span.icon.navbar-metric-dropdown {
    margin: 0;
    position: relative;
    top: 4px;
    right: 2px;
    width: 6px;
    height: 4px;
  }
  .navbar-metric-dropdown i.mdi {
    width: 10px;
  }
  .navbar-metric-dropdown i.mdi::before {
    font-size: 1rem;
  }

  #navbar-stats-area {
    min-width: 240px;
    max-width: 22vw;
  }
  #navbar-stats-area.has-metrics {
    min-width: 300px;
  }
  #navbar-stats-area.has-metrics {
    min-width: 112px;
  }
  #navbar-stats-area.has-gear-icon {
    min-width: 332px;
  }
  .has-aside-expanded #navbar-company-name {
    /* full width - open sidebar - arrow icon - stats - name dropdown - right buttons */
    max-width: calc(100vw - 15rem - 52px - 15rem - 22vw - 17vw - 64px);
  }
  .has-aside-expanded #navbar-company-name {
    max-width: calc(100vw - 15rem - 52px - 112px - 22vw - 17vw - 64px);
  }
  .has-aside-expanded .has-company-logo #navbar-company-name {
    /* full width - open sidebar - arrow icon - logo - stats - name dropdown - right buttons */
    max-width: calc(100vw - 15rem - 52px - 52px - 15rem - 22vw - 17vw - 64px);
  }
  .has-aside-expanded .has-company-logo #navbar-company-name {
    max-width: calc(100vw - 15rem - 52px - 52px - 112px - 22vw - 17vw - 64px);
  }
  .has-company-logo #navbar-company-name {
    /* full width - closed sidebar - arrow icon - stats - name dropdown - right buttons */
    max-width: calc(100vw - 4.5rem - 52px - 52px - 15rem - 22vw - 17vw - 64px);
  }
  .has-company-logo #navbar-company-name {
    max-width: calc(100vw - 4.5rem - 52px - 52px - 112px - 22vw - 17vw - 64px);
  }
  #navbar-company-name {
    transition: max-width 300ms ease-in-out;
    /* full width - closed sidebar - arrow icon -  stats - right side */
    max-width: calc(100vw - 4.5rem - 52px - 15rem - 22vw - 17vw - 64px);
    min-width: 48px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .is-user-name {
    display: flex;
    align-content: center;
  }

  .limit-username-length {
    display: inline-block;
    max-width: 17vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: max-width 300ms ease-in-out;
  }
  #disconnected-tooltip .tooltip-trigger {
    display: flex;
    align-items: center;
  }
  #disconnected-tooltip:after {
      white-space: normal;
      max-width: 30vw;
      width: 580px;
      min-width: 240px;
      text-align: center;
    }
  /* standard breakpoint for formatting between tablet and widescreen */
  @media screen and (max-width: 1215px) {
    .has-aside-expanded .limit-username-length {
      max-width: 16vw;
    }
  }
  /* random number where this needs to be smaller if we have metrics */
  @media screen and (max-width: 1100px) {
    .has-aside-expanded .has-metrics .limit-username-length{
      max-width: 11vw;
    }
  }
  @media screen and (max-width: 1023px) {
    #disconnected-tooltip:after {
      max-width: 30vw;
    }
  }
  @media screen and (max-width: 768px) {
    #disconnected-tooltip:after {
      white-space: normal;
      width: 240px;
      text-align: center;
    }
  }
  @media screen and (max-width: 450px) {
    .control.time-control {
      font-size: 0.8rem;
    }
    #navbar-stats-area,
    #navbar-stats-area.has-metrics,
    #navbar-stats-area.has-gear-icon {
      padding-right: 0;
      min-width: 256px;
    }
  }

  #timezone-alert {
    width: 14px;
  }
  #timezone-alert span i {
    position: relative;
    left: 2px;
  }
  .is-user-dropdown .navbar-link > span.icon i {
    display: flex;
    align-items: center;
  }
</style>
