<template>
  <modal-card :active="active" @close="onClose" title="ChronoCards support">
    <b-tabs v-model="activeTab" @input="onTabInput">
      <b-tab-item label="Support">
        <p style="margin-bottom: 18px;">
          Questions, ideas, or feedback? Send us a message and support will reach out shortly!
        </p>
        <b-field label="Title">
          <b-input :placeholder="randomPlaceholders[randomPlaceholderIndex].title" v-model="support.title" />
        </b-field>
        <b-field label="Description"  message="You may be contacted for further details.">
          <b-input type="textarea" :placeholder="randomPlaceholders[randomPlaceholderIndex].description" v-model="support.body" />
        </b-field>
      </b-tab-item>
      <b-tab-item headerClass="custom-tab-header-link" style="background-color: blue;">
        <template slot="header">
          Docs
          <b-icon icon="open-in-new" size="is-small" class="inline-small-icon" />
        </template>
      </b-tab-item>
      <b-tab-item headerClass="custom-tab-header-link" style="background-color: blue;">
        <template slot="header">
          Terms &amp; Conditions
          <b-icon icon="open-in-new" size="is-small" class="inline-small-icon" />
        </template>
      </b-tab-item>
    </b-tabs>
    <template v-slot:footer>
      <b-button v-if="activeTab === 0" @click="submitTicket" :loading="submittingSupport" class="is-primary">
        Submit
      </b-button>
      <b-button class="is-outlined" @click="onClose">Close</b-button>
    </template>
  </modal-card>
</template>

<script>
import { dtFormat } from '@/utils'
import ModalCard from '@/components/common/ModalCard.vue'
export default {
  components: { ModalCard },
  props: ['active'],
  watch: {
    active (to) {
      if (to) {
        this.randomPlaceholderIndex = Math.round(Math.random() * (this.randomPlaceholders.length - 1))
        this.activeTab = 0
        this.support = {
          title: '',
          body: ''
        }
      }
    }
  },
  data () {
    return {
      activeTab: 0,
      support: {
        title: '',
        body: ''
      },
      submittingSupport: false,
      randomPlaceholderIndex: 0,
      randomPlaceholders: [
        {
          title: 'Can you help me turn back time?',
          description: "I'd like to go back in time to reclaim all of the precious time I've wasted. Can ChronoCards help?"
        },
        {
          title: 'My mother said I need to clean my room. How can ChronoCards help?',
          description: "I really don't want to clean my room. Can you do it for me?"
        },
        {
          title: 'I am confused by the nature of time',
          description: 'Can you explain to me how it works? Why is it always going forwards?'
        },
        {
          title: 'What is the best way to prevent aging?',
          description: 'It seems like you guys know a lot about time, do you know how to stop the aging process?'
        },
        {
          title: 'Can you help me stop time?',
          description: "I saw it happen once in a zany 80's movie and it looked like a lot of fun."
        },
        {
          title: "That's a Texas sized 10-4",
          description: 'May I please know what that means?'
        }
      ]
    }
  },
  methods: {
    onClose () {
      this.$emit('update:active', false)
      this.$emit('close')
    },
    submitTicket () {
      if (this.support.title < 1) {
        this.$buefy.snackbar.open({
          message: 'Support tickets must have a title',
          type: 'is-danger',
          duration: 4500
        })
        return Promise.resolve()
      } else if (this.support.body.length < 10) {
        this.$buefy.snackbar.open({
          message: 'Support tickets must be at least 10 characters long',
          type: 'is-danger',
          duration: 4500
        })
        return Promise.resolve()
      }
      const ticket = Object.assign({ version: this.VUE_APP_VERSION }, this.support)
      this.submittingSupport = true
      return this.axios.post('/api/support', ticket).then(() => {
        this.support = {
          title: '',
          body: ''
        }
        this.onClose() // close only on success
      }).finally(() => {
        this.submittingSupport = false
      })
    },
    onTabInput (newTab) {
      // tab is docs, open them and don't show the blank contents of that tab
      if (newTab === 1) {
        this.$nextTick(() => { // and if you open this too fast it didn't switch the tab...
          this.openDocs()
        })
      } else if (newTab === 2) {
        this.$nextTick(() => {
          this.openTerms()
        })
      }
    },
    openDocs () {
      this.activeTab = 0
      window.open('https://chrono.cards/docs/', '_blank')
    },
    openTerms () {
      this.activeTab = 0
      window.open('https://chrono.cards/terms-and-conditions/', '_blank')
    },
    dtFormat
  }
}
</script>

<style>
  .article-title {
    font-size: large;
    font-weight: bold;
  }
  .article-date {
    font-size: 0.7rem;
    color: gray;
  }
</style>
