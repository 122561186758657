import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import { titleCase } from './utils'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: 'error'
    },
    { // redirect here so home route does not match all
      path: '/',
      redirect: 'my-report'
    },
    {
      // in case someone still hits overview, we'll take them to their report now
      path: '/overview',
      redirect: 'my-report'
    },
    {
      path: '/profile',
      name: 'profile',
      meta: {
        title: 'My Profile'
      },
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue')
    },
    {
      path: '/my-report/:tab?',
      name: 'my-report',
      component: () => import(/* webpackChunkName: "my-report" */ './views/MyReport.vue'),
      meta: {
        title: function (route) {
          return `My ${titleCase(route.params?.tab) || 'Report'}`
        }
      }
    },
    {
      path: '/company/:tab?',
      name: 'company', // give this route name so sidebar is happy
      component: () => import(/* webpackChunkName: "company" */ './views/Company.vue'),
      meta: {
        title: function (route) {
          return `Company ${titleCase(route.params?.tab) || 'Report'}`
        }
      }
    },
    {
      path: '/teams/:teamId?/:tab?',
      name: 'teams',
      component: () => import(/* webpackChunkName: "team" */ './views/Team.vue'),
      meta: {
        title: function (route) {
          const { teamId, tab } = route.params
          if (!teamId) {
            return 'Teams'
          }
          const teamName = (store.state.activeTeam?.name || 'Team') + ' '
          return teamName + titleCase(tab || 'report')
        }
      }
    },
    {
      path: '/users/:userId?/:tab?',
      name: 'users',
      component: () => import(/* webpackChunkName: "user" */ './views/User.vue'),
      meta: {
        title: function (route) {
          const { userId, tab } = route.params
          if (!userId) {
            return 'Users'
          }
          const isSelf = parseInt(userId) === parseInt(store.state.user.id)
          const name = (isSelf ? `${store.state.user.first_name} ${store.state.user.last_name}` : store.state.activeUser ? `${store.state.activeUser.first_name} ${store.state.activeUser.last_name}` : 'User') + ' '
          return name + titleCase(tab || 'report')
        }
      }
    },
    {
      path: '',
      component: () => import(/* webpackChunkName: "full-page" */ './views/FullPage.vue'),
      children: [
        {
          path: '/auth/register',
          name: 'register',
          component: () => import(/* webpackChunkName "full-page" */ './views/full-page/Register.vue'),
          meta: { title: 'Register Company' }
        },
        {
          path: '/auth/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Login.vue')
        },
        {
          path: '/auth/logout',
          name: 'logout',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Logout.vue')
        },
        {
          path: '/error',
          name: 'error',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue')
        },
        {
          // weird, yeah, but if we change this URL i'll have to ask people to update the CC oauth app redirect url in portal
          path: '/profile/oauth/arc',
          redirect: { name: 'login' }
        },
        {
          // but we'll also throw this one in here so that going forward we can use a more sensible URL.
          path: '/auth/login/oauth',
          redirect: { name: 'login' }
        }
      ]
    },
    {
      path: '/arc-addin',
      component: () => import(/* webpackChunkName: "arc-addin" */ './views/arc/ArcVersions.vue'),
      name: 'arc-versions',
      meta: {
        title: 'Arc Versions',
        noAuth: true
      }
    },
    {
      path: '/workflows/:workflowID',
      component: () => import(/* webpackChunkName: "arc-addin" */ './components/workforce/workflows/WorkflowPage.vue'),
      name: 'workflow',
      meta: {
        title: 'ArcGIS Pro Workflow'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
