/*
  general time manipulation functions
*/

import moment from 'moment-timezone'

function hFormat (value, forceShowSeconds = false, forceHideSeconds = false) { // hours decimal number to hours:minutes:seconds
  if (Number.isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
    return forceHideSeconds ? '0:00' : '0:00:00'
  }
  value = parseFloat(value) // ensure number cast
  const fixNum = (num) => num < 10 ? `0${num}` : num
  const seconds = parseInt(value * 60 * 60) % 60
  const minutes = parseInt(value * 60) % 60
  const hours = Math.floor(value)
  if ((forceShowSeconds || (hours < 1 && minutes < 1 && seconds !== 0)) && !forceHideSeconds) {
    return `${hours}:${fixNum(minutes)}:${fixNum(seconds)}`
  } else {
    return `${hours}:${fixNum(minutes)}`
  }
}

function tzMo (dateString) {
  // creates a moment object in the current timezone
  if ('vm' in window) {
    return moment.utc(dateString).tz(window.vm.$store.getters.currentTimezoneSetting)
  } else {
    return moment.utc(dateString).tz(moment.tz.guess())
  }
}

function mo (dateString) {
  // creates a moment object (no translation. just shorter than moment everywhere)
  return moment(dateString)
}

function dFormat (dateString, noTranslate = false) { // format DATE with user setting or default 'M/D/YYYY'
  if (!dateString || !mo(dateString).isValid()) {
    return ''
  }
  let format = vm && vm.$store ? vm.$store.getters.dFormatSetting : 'M/D/YYYY' // eslint-disable-line
  return noTranslate ? mo(dateString).format(format) : tzMo(dateString).format(format)
}

function tFormat (dateString, noTranslate = false) { // format TIME with user setting or default 'h:mm a'
  if (!dateString || !mo(dateString).isValid()) {
    return ''
  }
  let format = vm && vm.$store ? vm.$store.getters.tFormatSetting : 'h:mm a' // eslint-disable-line
  return noTranslate ? mo(dateString).format(format) : tzMo(dateString).format(format)
}

function dtFormat (dateString, noTranslate = false) { // format DATETIME with user setting or default 'M/D/YYYY h:mm a'
  if (!dateString || !mo(dateString).isValid()) {
    return ''
  }
  let format = vm && vm.$store ? vm.$store.getters.dtFormatSetting : 'M/D/YYYY h:mm a' // eslint-disable-line
  return noTranslate ? mo(dateString).format(format) : tzMo(dateString).format(format)
}

export {
  hFormat,
  tzMo,
  mo,
  dFormat,
  tFormat,
  dtFormat
}
