import Vue from 'vue'

// module designed for vuex 3.6, there are changes to schema if we update to 4x (4x is latest 2023-04-13)
// https://v3.vuex.vuejs.org/guide/modules.html#modules

// state must be a function
const state = () => ({
  editing: false,
  events: []
})

// mutation must be an object with named functions
const mutations = {
  setWorkflowEvents (state, events = []) {
    Vue.set(state, 'events', events)
    if (!state.events.length && state.editing) {
      Vue.set(state, 'editing', false)
    }
  },
  removeWorkflow (state, id) {
    Vue.set(state, 'events', state.events.filter(e => parseInt(e.id) === parseInt(id)))
  },
  setWorkflowEditing (state, val = false) {
    Vue.set(state, 'editing', val)
  }
}

export default {
  state,
  mutations
}
