const general = [ // everyone sees this
  'General',
  [
    {
      to: { name: 'my-report' },
      icon: 'account',
      label: 'My Report',
      id: 'aside-my-report'
    }
  ]
]

const workforce = {
  companyAdmin: [
    'People',
    [
      {
        to: { name: 'company' },
        icon: 'domain',
        label: 'Company',
        id: 'aside-company'
      },
      {
        to: { name: 'teams' },
        icon: 'account-group',
        label: 'Teams',
        id: 'aside-teams'
      },
      {
        to: { name: 'users' },
        icon: 'account-card-details',
        label: 'Users',
        id: 'aside-users'
      }
    ]
  ],
  teamManager: [ // you manage at least 1 team
    // you can see/edit info about a team as well as the individual users on that team
    'People',
    [
      {
        to: { name: 'teams' },
        icon: 'account-group',
        label: 'Teams',
        id: 'aside-teams'
      },
      {
        to: { name: 'users' },
        icon: 'account-card-details',
        label: 'Users',
        id: 'aside-users'
      }
    ]
  ],
  teamMember: [ // can access at least 1 team
    // if this team is limited you only get the report tab and no user/personal data is returned
    'People',
    [
      {
        to: { name: 'teams' },
        icon: 'account-group',
        label: 'Teams',
        id: 'aside-teams'
      }
    ]
  ]
}

export function makeLeftMenu (auth) {
  let menuItems = [...general]
  if (auth.is('admin')) {
    menuItems = menuItems.concat(workforce.companyAdmin)
  } else if (auth.user()?.teams?.some(access => access.access_level === 'manager')) {
    menuItems = menuItems.concat(workforce.teamManager)
  } else if (auth.user()?.teams?.some(access => ['manager', 'member', 'limited'].includes(access.access_level)) && auth.user().teams.some(access => ['member', 'manager'].includes(access.access_level))) {
    menuItems = menuItems.concat(workforce.teamMember)
  }
  return menuItems
}

export function makeLeftMenuBottom (auth) {
  if (!auth.user()) {
    return [] // NO sidebar if you're logged out
  }
  return [
    {
      to: { name: 'logout' },
      icon: 'logout',
      label: 'Log Out',
      state: 'chrono',
      id: 'aside-logout'
    }
  ]
}
