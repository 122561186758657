/*
  functions that are for cross-browser compatibility
  or functions that directly deal with browser capability/permissions
*/
import axios from '../network'

/**
  @param {String} content stringified content to be downloaded as a file
  @param {String} filename the name of the file to be downloaded, including extension
  @param {String} type the mimetype of the file, ie 'text/csv'. defaults to 'text/json'
  @returns {null} file is downloaded in user's browser automatically

  uses the type of the arc event + it's payload to generate a human readable string.
  use detailed for a more elaborate and longer description of the event
*/
function downloadFile (content, filename, type = 'text/json') {
  // https://stackoverflow.com/a/65939108/3865600
  // takes `content` and downloads it as `filename` (be sure to add the extension)
  const stringContent = typeof content === 'string' ? content : JSON.stringify(content) // double stringify messed up csv downloads
  const blob = new Blob([stringContent], { type })
  const link = document.createElement('a')

  link.download = filename
  link.href = window.URL.createObjectURL(blob)
  link.dataset.downloadurl = [type, link.download, link.href].join(':')

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  })

  link.dispatchEvent(evt)
  link.remove()
}

/**
 * Fetches an image from the given URL and converts it to a base64-encoded string.
 *
 * @param {string} url - The URL of the image to fetch.
 * @returns {Promise<string|null>} A promise that resolves to a base64-encoded string of the image data,
 *                                 or null if the content is not an image or an error occurs.
 *
 * @throws Will log an error message if the fetch fails or if the content type is not an image.
 */
async function fetchImageAsBase64 (url) {
  try {
    const response = await axios.get(url, { responseType: 'blob' })

    // Check if the content type is an image
    const contentType = response.headers['content-type']
    if (!contentType.startsWith('image/')) {
      throw new Error(`Invalid content type: ${contentType}`)
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(response.data)
    })
  } catch (error) {
    console.error(`Error fetching image from ${url}:`, error)
    return null // Return null or handle as needed
  }
}

export {
  downloadFile,
  fetchImageAsBase64
}
