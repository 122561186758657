import { addS, listJoin } from '../data-utils'
import { splitLower, wrap, dataConnectionDisplay, fieldMappingToTable } from './payload-utils'
import { convertEdit } from './edit-utils'
import axios from '../../network'
/**
  @param {Object} arcEvent the arc event WITH PAYLOAD
  @param {Number} detailLevel 0=short 1=full 2=workflow
  @returns {String} human readable string describing the event || simplified json payload || null

  uses the type of the arc event + it's payload to generate a human readable string as html.
*/
export function parsedArcEventDescription (arcEvent, detailLevel = 0) {
  // return nothing if this is invalid, or has no payload contents at all
  if (!arcEvent || !arcEvent.payload || typeof arcEvent.payload !== 'object') {
    return ''
  }
  // payload to string conversion map
  const parseMap = {
    'application-startup': (p) => {
      let text = 'Started ArcGIS Pro'
      if (p?.license) {
        text += ` <small>(${p.license})</small>`
      }
      if (arcEvent.project_name) {
        text += ` by double clicking ${arcEvent.project_name}`
      }
      text += '.'
      if (detailLevel && p.project) {
        text += wrap(`<small>Project URI: ${p.project.uri}</small>`)
      }
      return wrap(text)
    },
    'application-closing': p => {
      const text = p.cancel ? 'Cancelled closing ArcGIS Pro.' : 'Closed ArcGIS Pro.'
      return wrap(text)
    },
    'project-opened': p => {
      // https://pro.arcgis.com/en/pro-app/latest/sdk/api-reference/index.html#topic9237.html
      const openMode = {
        None: null,
        CreateBlank: 'a new project',
        CreateFromTemplate: 'a new project from template',
        OpenExisting: 'an existing project'
      }[p.projectOpenMode]
      let text = `Opened ${openMode || 'a project'}: ${p.project?.name || 'untitled'}.`
      if (detailLevel && p.project) {
        text += wrap(`<small>Project URI: ${p.project.uri}</small>`)
      }
      return wrap(text)
    },
    'project-created': p => {
      const openMode = {
        CreateBlank: 'a new project',
        CreateFromTemplate: 'a new project from template'
      }[p.projectOpenMode]
      let text = `Created ${openMode || 'a project'}: ${p.project?.name || 'untitled'}`
      if (detailLevel && p.project) {
        text += wrap(`<small>Project URI: ${p.project.uri}</small>`)
      }
      return wrap(text)
    },
    'project-closed': p => {
      let text = `Closed project: ${(p.project && p.project.name) || 'untitled'}.`
      if (detailLevel && p.project) {
        text += wrap(`<small>Project URI: ${p.project.uri}</small>`)
      }
      return wrap(text)
    },
    'project-saved': p => {
      let text = `Saved project: ${(p.project && p.project.name) || 'untitled'}.`
      if (detailLevel && p.project) {
        text += wrap(`<small>Project URI: ${p.project.uri}</small>`)
      }
      return wrap(text)
    },
    'active-tool-changed': p => {
      let toolText = 'Switch tools'
      if (p.previousID) {
        toolText += ` from ${p.previousID}`
      }
      if (p.currentID) {
        toolText += ` to ${p.currentID}`
      }
      toolText += '.'
      return wrap(toolText)
    },
    'edit-operation': p => {
      return convertEdit(arcEvent, p, detailLevel)
    },
    'map-member-properties-changed': p => {
      // as we fill out more custom event names, hopefully the number of ambiguous events decreases
      const customEvents = {
        expanded: 'Expanded contents of',
        collapsed: 'Collapsed contents of',
        shown: 'Changed visibility to shown on',
        hidden: 'Changed visibility to hidden on'
      }
      let propText = ''
      if (Array.isArray(p.changes)) {
        p.changes.some((change, changeIndex) => {
          let hintText = Object.keys(customEvents).includes(change.hint) ? customEvents[change.hint] : `Changed ${change.hint.toLowerCase()} on`
          if (change.hint === 'Any') {
            hintText = 'Made a change to'
          }
          const changeText = `${hintText} layer ${change.member.layer?.name} on map ${change.member.map?.name}. `
          propText += wrap(changeText)
          if (!detailLevel && changeIndex === 1 && p.changes.length > 2) {
            const remaining = p.changes.length - 2
            propText += wrap(`<small>${remaining} more row${addS(remaining)}...</small>`)
            return true // cut loop short
          }
          return false
        })
      }
      return propText
    },
    'layers-added': p => {
      const layerNames = p.layers.map(l => l.name)
      const mapNames = p.maps.map(m => m.name)

      const layerList = !detailLevel && layerNames.length > 3 ? `${layerNames[0]}, ${layerNames[1]}, and ${layerNames.length - 2} other layers` : listJoin(layerNames)
      const mapList = !detailLevel && mapNames.length > 3 ? `${mapNames[0]}, ${mapNames[1]}, and ${mapNames.length - 2} other maps` : listJoin(mapNames)
      let text = `Added layer${addS(layerNames)} <i>${layerList}</i> to map${addS(mapNames)} <i>${mapList}</i>.`
      if (detailLevel) {
        p.layers.forEach(l => {
          text += dataConnectionDisplay(l.dataConnection)
        })
      }
      return wrap(text)
    },
    'layers-moved': p => {
      const layerNames = p.layers.map(l => l.name)
      const mapNames = p.maps.map(m => m.name)

      const layerList = !detailLevel && layerNames.length > 3 ? `${layerNames[0]}, ${layerNames[1]}, and ${layerNames.length - 2} other layers` : listJoin(layerNames)
      const mapList = !detailLevel && mapNames.length > 3 ? `${mapNames[0]}, ${mapNames[1]}, and ${mapNames.length - 2} other maps` : listJoin(mapNames)
      let text = `Moved layer${addS(layerNames)} <i>${layerList}</i> to map${addS(mapNames)} <i>${mapList}</i>.`
      if (detailLevel) {
        p.layers.forEach(l => {
          text += dataConnectionDisplay(l.dataConnection)
        })
      }
      return wrap(text)
    },
    'layers-removed': p => {
      const layerNames = p.layers.map(l => l.name)
      const mapNames = p.maps.map(m => m.name)

      const layerList = !detailLevel && layerNames.length > 3 ? `${layerNames[0]}, ${layerNames[1]}, and ${layerNames.length - 2} other layers` : listJoin(layerNames)
      const mapList = !detailLevel && mapNames.length > 3 ? `${mapNames[0]}, ${mapNames[1]}, and ${mapNames.length - 2} other maps` : listJoin(mapNames)
      let text = `Removed layer${addS(layerNames)} <i>${layerList}</i> from map${addS(mapNames)} <i>${mapList}</i>.`
      if (detailLevel) {
        p.layers.forEach(l => {
          text += dataConnectionDisplay(l.dataConnection)
        })
      }
      return wrap(text)
    },
    'edit-started': p => {
      return wrap(`Started operation "${p.operation ? p.operation.name : p.operationName}" with id: ${p.guid}`)
    },
    'edit-completing': p => {
      return wrap(`Finished operation with id: ${p.guid}`)
    },
    // edit-completed events that aren't an operation don't have useful info so just say what it is.
    'edit-save': () => {
      return wrap('Saved an edit operation.')
    },
    'edit-discard': () => {
      return wrap('Discarded an edit.')
    },
    'edit-undo': () => {
      return wrap('An edit was undone.')
    },
    'edit-redo': () => {
      return wrap('An edit was redone.')
    },
    'edit-post': () => {
      return wrap('An edit was posted.')
    },
    'edit-reconcile': () => {
      return wrap('An edit was reconciled.')
    },
    'map-closed': p => {
      return wrap(`Closed ${(p.mapPane?.mapView?.map?.name) || 'map'}.`)
    },
    'map-series': p => {
      let pageInfo = p.currentPageNumber ? `#${p.currentPageNumber}` : ''
      if (p.currentPageName) {
        pageInfo += ` with name ${p.currentPageName}`
      }
      if (pageInfo.length) {
        pageInfo = ' on page ' + pageInfo
      }
      // these are the only 2 types for map-series events
      let text = ''
      if (p.type === 'PageUpdated') {
        text = `Map series was updated${pageInfo}.`
      } else if (p.type === 'Refreshed') {
        text = `Map series was refresh${pageInfo}`
      }
      return wrap(text)
    },
    'standalone-tables-added': p => {
      let tableText = ''
      if (Array.isArray(p.tables)) {
        const mapGroup = p.tables.reduce((acc, curr) => { // group these by the same map so that we can use less repetitive wording
          acc[curr.map?.name] = acc[curr.map?.name] || [] // init array or keep existing
          acc[curr.map?.name].push(curr.name) // add name to map grouping
          return acc
        }, {})
        Object.keys(mapGroup).forEach(mapName => {
          tableText += `Added table${addS(mapGroup[mapName])} [${mapGroup[mapName].join(', ')}] to map ${mapName}.`
        })
      }
      return wrap(tableText)
    },
    'standalone-tables-removed': p => {
      let tableText = ''
      if (Array.isArray(p.tables)) {
        const mapGroup = p.tables.reduce((acc, curr) => { // group these by the same map so that we can use less repetitive wording
          acc[curr.map?.name] = acc[curr.map?.name] || [] // init array or keep existing
          acc[curr.map?.name].push(curr.name) // add name to map grouping
          return acc
        }, {})
        Object.keys(mapGroup).forEach(mapName => {
          tableText += `Removed table${addS(mapGroup[mapName])} [${mapGroup[mapName].join(', ')}] from map ${mapName}.`
        })
      }
      return wrap(tableText)
    },
    'animation-export-finished': p => {
      let aniText = `An animation was exported of ${p.map?.name}`
      if (Array.isArray(p.layers) && p.layers.length) {
        aniText += ` using layers ${p.layers.map(l => l.name).join(', ')}`
      }
      aniText += '.'
      if (detailLevel) {
        p.layers.forEach(l => {
          aniText += dataConnectionDisplay(l.dataConnection)
        })
      }
      return wrap(aniText)
    },
    'layout-added': p => {
      return wrap(`Added layout ${p.name}.`)
    },
    'layout-removed': p => {
      return wrap(`Removed layout ${p.name}.`)
    },
    'elements-updated': p => {
      let updateText = ''
      const wasWere = p.elementNames?.length === 1 ? 'was' : 'were'
      if (!p.elementNames?.length) {
        updateText += 'elements'
      } else {
        if (p.elementNames.length <= 3 || detailLevel) {
          updateText += listJoin(p.elementNames, 3)
        } else {
          updateText += p.elementNames.length + ' elements'
        }
      }
      updateText = updateText + ` ${wasWere} updated on a layout.`
      return wrap(updateText)
    },
    'elements-placement-changed': p => {
      let updateText = ''
      if (Array.isArray(p.elementNames) && p.elementNames.length) {
        if (p.elementNames.length <= 3 || detailLevel) {
          updateText += listJoin(p.elementNames, 3)
        } else {
          updateText += p.elementNames.length + ' element' + addS(p.elementNames.length)
        }
      } else {
        updateText += 'elements'
      }
      const wasWere = p.elementNames?.length === 1 ? 'was' : 'were'
      updateText += ` ${wasWere} moved on a layout.`
      return wrap(updateText)
    },
    'elements-style-changed': p => {
      let updateText = ''
      if (Array.isArray(p.elementNames) && p.elementNames.length) {
        if (p.elementNames.length <= 3 || detailLevel) {
          updateText += listJoin(p.elementNames, 3)
        } else {
          updateText += p.elementNames.length + ' element' + addS(p.elementNames.length)
        }
      } else {
        updateText += 'elements'
      }
      const wasWere = p.elementNames?.length === 1 ? 'was' : 'were'
      updateText += ` styles ${wasWere} updated on a layout.`
      return wrap(updateText)
    },
    'elements-added': p => {
      let updateText = ''
      if (Array.isArray(p.elementNames) && p.elementNames.length) {
        if (p.elementNames.length <= 3 || detailLevel) {
          updateText += listJoin(p.elementNames, 3)
        } else {
          updateText += p.elementNames.length + ' element' + addS(p.elementNames.length)
        }
      } else {
        updateText += 'elements'
      }
      const wasWere = p.elementNames?.length === 1 ? 'was' : 'were'
      updateText += ` ${wasWere} added to a layout.`
      return wrap(updateText)
    },
    'elements-removed': p => {
      let updateText = ''
      if (Array.isArray(p.elementNames) && p.elementNames.length) {
        if (p.elementNames.length <= 3 || detailLevel) {
          updateText += listJoin(p.elementNames, 3)
        } else {
          updateText += p.elementNames.length + ' element' + addS(p.elementNames.length)
        }
      } else {
        updateText += 'elements'
      }
      const wasWere = p.elementNames?.length === 1 ? 'was' : 'were'
      updateText += ` ${wasWere} removed from a layout.`
      return wrap(updateText)
    },
    'page-changed': p => {
      const fallback = 'The size or style of the active page was changed.' // idk what identical old/new means
      const changes = [] // strings of changes to be joined if found
      if (p.newPage.width !== p.oldPage.width) {
        changes.push(`The width of the page was changed from ${p.oldPage.width} to ${p.newPage.width}.`)
      }
      if (p.newPage.height !== p.oldPage.height) {
        changes.push(`The height of the page was changed from ${p.oldPage.height} to ${p.newPage.height}.`)
      }
      // we could also diff these fields (with type shown)
      // guides[], Margin{}, UnitName"", ShowGuides!, ShowMargin!, ShowRulers!, StretchElements!, PrinterPreferences{}
      const text = changes.length ? changes.join(' ') : fallback
      return wrap(text)
    },
    'portal-sign-on-changed': p => {
      const text = p.isSignedOn ? `Signed in to ${p.portalURI}` : `Signed out of ${p.portalURI}`
      return wrap(text)
    },
    'map-added': p => {
      const text = `Map "${p.name}" was added.`
      return wrap(text)
    },
    'map-removed': p => {
      const text = `${p.name} was removed.`
      return wrap(text)
    },
    // there is more info here, but this is what is FOR SURE useful right away
    // there are status texts, and some fields I've never seen populated
    // FIXME needs more testing, at least for failed processes to see more differences in payloads
    'project-items-changed': p => {
      const itemToString = item => {
        if (item.type === 'GP') {
          // if detailed we don't want to be repetitive
          let str = detailLevel ? '' : wrap(`Ran geoprocessing tool: ${item.title || item.name}.`)
          if (detailLevel) {
            if (item.extraParameters) {
              if (Array.isArray(item.extraParameters.params?.param) && item.extraParameters.params.param.length) {
                str += wrap('<b>Parameters:</b>')
                item.extraParameters.params.param.forEach(param => {
                  if (param.text !== null && param.text !== undefined) {
                    if (param.name === 'field_mapping') { // format mapped fields as a table instead of as an illegible string
                      str += `<p style="margin-left: 1rem;"><b>${param.displayname || param.name}</b></p>` // tables cannot be inside p tags, so the table is added separate
                      str += `<div style="margin-left: 2rem" class="table-container">${fieldMappingToTable(param.text)}</div>`
                    } else {
                      const paramText = `<b>${param.displayname || param.name}</b>: ${param.text}`
                      str += `<p style="margin-left: 1rem;">${paramText}</p>`
                    }
                  }
                })
                if (!item.extraParameters.params.param.length) {
                  str += '<p style="margin-left: 1rem;">No parameters.</p>'
                }
              }
              if (Array.isArray(item.extraParameters.environments?.param) && item.extraParameters.environments.param.length) {
                str += wrap('<b>Environments:</b>')
                item.extraParameters.environments.param.forEach(param => {
                  if (param.text) {
                    const paramText = `<b>${param.displayname || param.name}</b>: ${param.text}`
                    str += `<p style="margin-left: 1rem;">${paramText}</p>`
                  }
                })
              }
              if (Array.isArray(item.extraParameters.messages?.msg)) {
                str += wrap('<b>Messages:</b>')
                item.extraParameters.messages.msg.forEach(message => {
                  if (message.text) {
                    str += `<p style="margin-left: 1rem;">${message.text}</p>`
                  }
                })
                if (!item.extraParameters.messages.msg.length) {
                  str += '<p style="margin-left: 1rem;">No messages to show. There may be messages available once the tool has finished processing.</p>'
                }
              }
              const layerParams = item.extraParameters.params?.param.reduce((layers, param) => {
                if (param && param.layer && !layers.find(l => l.name === param.layer.name && l.dataConnection?.workspaceConnectionString === param.layer.dataConnection?.workspaceConnectionString)) {
                  layers.push(param.layer)
                }
                return layers
              }, []) || []
              if (layerParams.length) {
                str += wrap('<b>Layers:</b>')
              }
              layerParams.forEach(layer => {
                let dcStr = dataConnectionDisplay(layer.dataConnection, false) // uri as string || ''
                dcStr = dcStr.length ? `<small>(</small>${dcStr}<small>)</small>` : dcStr // add markup only if dataconnection exists
                str += wrap(`<b>${layer.name}</b> ${dcStr}`, 1)
                str += wrap(`<b>Spatial Reference:</b> ${layer.wkid} (projected) and ${layer.gcsWkid} (GCS)`, 2)
                if (layer.activeDefinitionQuery) {
                  str += wrap(`<b>Definition Query:</b> ${layer.activeDefinitionQuery.name}`, 2)
                  str += wrap(`<code>${layer.activeDefinitionQuery.whereClause}</code>`, 3)
                }
              })
            } else {
              const noDataText = 'No additional data to show at this time.'
              const infoText = 'Additional information about input parameters, result, and operation duration'
              const saveText = 'may be available after saving your work in ArcGIS Pro.'
              str += wrap(`${noDataText} ${infoText} ${saveText}`)
            }
          }
          return str
        } else {
          const text = `${p.action} ${splitLower(item.type)} named "${item.title || item.name}".`
          return wrap(text)
        }
      }
      return p.items?.map(itemToString).join(' ')
    },
    'map-property-changed': p => {
      let text = ''
      p.eventHints.forEach((hint, i) => {
        const map = p.maps?.[i]
        const newValue = p.updatedValues?.[i] // new to x.3.1 addins
        if (hint === 'any') {
          if (map) {
            text += wrap(`A property was changed on map <i>${map.name}</i>.`)
          } else {
            text += wrap('A property was changed on a map.')
          }
        } else if (hint && newValue?.length) {
          if (map) {
            text += wrap(`The ${splitLower(hint)} was changed on map <i>${map.name}</i> to ${newValue}.`)
          } else {
            text += wrap(`The ${splitLower(hint)} was changed to ${newValue}.`)
          }
        } else {
          if (map) {
            text += wrap(`The ${splitLower(hint)} was changed on map <i>${map.name}</i>.`)
          } else {
            text += wrap(`The ${splitLower(hint)} was changed on a map.`)
          }
        }
      })
      return text
    },
    'portal-added': p => {
      const text = `Added portal with url: ${p.portalURI}.`
      return wrap(text)
    },
    'portal-removed': p => {
      const text = `Removed portal with url: ${p.portalURI}.`
      return wrap(text)
    },
    'portal-changed': p => {
      const text = `Active portal changed to: ${p.portalURI}.`
      return wrap(text)
    },
    // so weird the docs say that the id should be used to get information about the exploratory analysis
    // yet the exploratory analysis object has the same {id, mapview}
    // so I don't know what is actually  contained in an exploratory analysis object...
    // I also don't know how to trigger this event reliably.
    // https://pro.arcgis.com/en/pro-app/sdk/api-reference/index.html#topic22640.html
    'exploratory-analysis-updated': p => {
      const text = p.mapView && p.mapView.map?.name ? `Exploratory analysis was updated for map ${p.mapView.map?.name}.` : 'Exploratory analysis was updated.'
      return wrap(text)
    },
    'analyst-note': p => wrap(p.text ?? 'N/A'),
    'logging-enabled': () => wrap('Logging ArcGIS Pro events to ChronoCards was enabled.'),
    'logging-paused': () => wrap('Logging ArcGIS Pro events to ChronoCards was disabled until next restart unless manually re-enabled.'),
    'logging-disabled': () => wrap('Logging ArcGIS Pro events to ChronoCards was disabled until manually re-enabled.'),
    'data-source-changed': (p) => {
      const fromText = dataConnectionDisplay(p.oldSource, false)
      const toText = dataConnectionDisplay(p.layer?.dataConnection, false)
      const text = `Changed the data source for layer ${p.layer?.name}`
      if (detailLevel) {
        return wrap(`${text} <p><b>Old source</b>: ${fromText}</p><p><b>New source</b>: ${toText}</p>`)
      } else {
        return wrap(text + '.')
      }
    },
    screenshot: p => {
      if (detailLevel && p.id) { // full or workflow details can just show the image
        const imgTag = `<img src="${axios.defaults.baseURL}/static/image-uploads/screenshots/${p.id}" loading="lazy" />`

        // center the image in modals, let tiptap manage the image layout in workflows.
        return detailLevel === 1 ? `<p class='content-centered'>${imgTag}</p>` : imgTag
      }

      // just show some text in the timeline or tables
      return wrap('A screenshot was taken using the ChronoCards ArcGIS Pro add-in.')
    },
    'toolbox-added': p => {
      const toolboxes = (p.items || []).map(i => i.name).filter(name => name)
      const s = toolboxes.length === 1 ? '' : 'es'
      let displayBoxes = toolboxes.slice(0, -1).join(', ') + (toolboxes.length > 2 ? ', and ' : toolboxes.length === 2 ? ' and ' : '') + toolboxes.slice(-1).join()
      displayBoxes = `<i>${displayBoxes}</i>`
      const projectInfo = arcEvent.project_name ? ` to project ${arcEvent.project_name}` : ''
      return wrap(`Added toolbox${s} ${displayBoxes}${projectInfo}`)
    },
    'toolbox-removed': p => {
      const toolboxes = (p.items || []).map(i => i.name).filter(name => name)
      const s = toolboxes.length === 1 ? '' : 'es'
      let displayBoxes = toolboxes.slice(0, -1).join(', ') + (toolboxes.length > 2 ? ', and ' : toolboxes.length === 2 ? ' and ' : '') + toolboxes.slice(-1).join()
      displayBoxes = `<i>${displayBoxes}</i>`
      const projectInfo = arcEvent.project_name ? ` from project ${arcEvent.project_name}` : ''
      return wrap(`Removed toolbox${s} ${displayBoxes}${projectInfo}.`)
    }
  }
  const type = arcEvent.type.includes(':') ? 'project-items-changed' : arcEvent.type // tool:name is really project-items-changed
  if (parseMap[type] && typeof parseMap[type] === 'function') {
    return parseMap[type](arcEvent.payload)
  } else {
    const text = simplifiedObjectString(arcEvent.payload)
    return wrap(text)
  }
}

/**
 @param someObject the data to be destructured into a string
 @return comma separated, null filtered, string representation (not reversible with JSON.parse)

  display version of JSON.stringify
  removes outer braces, key parenthesis and non-string parenthesis
  max depth of 3

  fallback for parsing arc event description
*/
export function simplifiedObjectString (someObject) {
  const joinArg = ', '
  const parseJoin = parseArray => parseArray.filter(s => s && s.length).join(joinArg)
  // recursive object parser to string output
  const parseObj = (obj, key = null, depth = 0, maxDepth = 3) => {
    const type = typeof obj
    const base = key ? `${key}: ` : ''

    if (!obj || depth > maxDepth) {
      return ''
    }
    if (type === 'string') {
      return `${base}"${obj}"`
    } else if (Array.isArray(obj)) {
      return `${base}[${parseJoin(obj.map(e => parseObj(e, null, depth + 1, maxDepth)))}]`
    } else if (type === 'object') {
      return `${base}{${parseJoin(Object.keys(obj).map(k => parseObj(obj[k], k, depth + 1, maxDepth)))}}`
    } else if (obj.toString) { // numbers/booleans unquoted
      return `${base}${obj.toString()}`
    }
    return ''
  }
  return parseJoin(Object.keys(someObject).map(k => parseObj(someObject[k], k)))
}
