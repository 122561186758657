import { NotificationProgrammatic as Notification } from 'buefy'

/**
 * Display a popup notification the same way we do in network.js
 *
 * @param {String} message
 * @param {Number} duration milliseconds
 * @param {Options} override any buefy notification options to override here (e.g. type)
 */
export function openError (message, duration = 5000, override = null) {
  Notification.open(Object.assign({
    message,
    type: 'is-danger',
    duration
  }, override))
}
