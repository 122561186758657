import { fetchImageAsBase64 } from '@/utils'

const Auth = {
  install (Vue, router, store) {
    // internal function that isn't exposed to $auth directly
    const onLogin = (responseData) => { // this is deceptively named, it also runs on refresh
      if (responseData && responseData.result) { // don't fuck this up if refresh without user info
        store.commit('setUser', responseData.result)
      }

      // if aside state was explicitly set, but it's currently not your last set preference, toggle it.
      if (store.state.user.settings?.asideOpen !== undefined && store.state.user.settings.asideOpen !== store.state.isAsideExpanded) {
        store.commit('asideStateToggle')
      }
      setTimeout(() => { // no nextTick use timeout
        document.documentElement.classList.add('is-ready') // add aside animation after login (prevents overflow issues)
      }, 0)
      store.dispatch('setTodayMetric')
      auth.configureRollbar()
      // now that we're logged in, open the timecard websocket to keep your time up to date across browsers
      subscribeToMetricsChanges()
      if (!store.state.appUserLoadComplete) {
        store.commit('onAppUserLoadComplete')
      }
      updateRefreshInterval() // make sure we are refreshing every ~15 mins
    }

    const subscribeToMetricsChanges = () => {
      if (window.vm.$socket.metrics || !auth.isLoggedIn()) {
        return
      }

      const socket = window.vm.$socket.init('metrics')
      socket.on('upsert', data => {
        store.commit('upsertStoredMetrics', data)
        store.dispatch('setTodayMetric')
      })
    }

    let refreshInterval = null // let auth handle it's own refresh interval
    // internal function to create a refresh interval if not exists
    const updateRefreshInterval = () => {
      if (!refreshInterval) {
        refreshInterval = setInterval(() => auth.refresh(true), 890000) // just shy of 15 minutes
      }
    }

    // creates a instance method that can be used inside of a Vue component
    const auth = {
      login (codeAndStateObj) {
        return Vue.axios.post('/api/auth/login', codeAndStateObj).then((response) => {
          store.commit('clearSpecificVuex')
          window.vm.$socket.destroyAll()
          if (response?.data?.result) {
            onLogin(response.data)

            // after successful login, set/update the last login in localstorage to make it easier to login next time
            const { avatar, email, first_name, id, last_name, portal_username } = response.data.result
            localStorage.setItem('lastLogin', JSON.stringify({ avatar, email, first_name, id, last_name, portal_username }))

            // avatars require auth to access. get/store this one as base64 so we can show it on the login page before you auth
            if (avatar && portal_username) { // avatar could be null or a string; only bother looking if something's there
              // if this req fails, it'll growl red but not prevent login.
              fetchImageAsBase64(`/api/images/avatar?portal_username=${portal_username}&avatar=${avatar}`).then(imgResp => {
                // no error handling here; it happens async. if it crashes, oh well-- it won't break the rest of the login.
                if (imgResp) {
                  localStorage.setItem('lastLogin', JSON.stringify(
                    { avatar: imgResp, email, first_name, id, last_name, portal_username }
                  ))
                }
              })
            }
          }
          if (router.history.current && router.history.current.params && router.history.current.params.nextLink) {
            router.push(router.history.current.params.nextLink)
          } else {
            router.push({ name: 'my-report' })
          }
          return Promise.resolve(response)
        }).catch(error => {
          // catch rollbar uncaught 400 for bad login
          if (error && error.response && error.response.data) {
            if (error.response.status === 400) {
              // oauth failed for some reason, so remove the state/code query args so that they're gone when a user refreshes
              router.replace({ query: null })
              return Promise.resolve(error) // catch errors we throw
            }
          }
          return Promise.reject(error)
        })
      },
      logout () {
        return Vue.axios.post('/api/auth/logout').finally(() => {
          // close/destroy any open sockets. the server just closed the socket in the req, so we can destroy it.
          window.vm.$socket.destroyAll() // yes, i know, window.vm... see above
          this.clearUser()
          // remove items from localstorage...not sure we need to touch contentRefreshTime, but this used to be localstorage.clear
          localStorage.removeItem('user')
          localStorage.removeItem('contentRefreshTime')
          store.commit('clearSpecificVuex') // removes user-specific vuex data
          document.documentElement.classList.remove('is-ready') // remove aside animations while logged out
          if (refreshInterval) { // stop refreshing
            clearInterval(refreshInterval)
            refreshInterval = null
          }
          if (router.history.current.name !== 'login') {
            router.push({ name: 'login' }).catch(error => Promise.resolve(error))
          }
          this.configureRollbar()
          return Promise.resolve(true)
        })
      },
      clearUser () {
        store.commit('setUser', {}) // will also remove localstorage
      },
      user () {
        return JSON.parse(localStorage.getItem('user'))
      },
      isLoggedIn () {
        return localStorage.getItem('user') != null
      },
      refresh (withUserInfo = false) {
        return Vue.axios.post(`/api/auth/refresh${withUserInfo ? '?withUserInfo=true' : ''}`).then(response => {
          onLogin(response.data) // should handle all the withUserInfo, rollbar, refresh interval, sockets connection, etc
        }).catch(() => {
          return this.logout()
        })
      },
      is (userRole) { // convenience method to see if a user is a role
        // first make sure a user is logged in
        if (!this.isLoggedIn()) {
          return false
        }

        return this.user().role === userRole
      },
      // you need this exact role by name
      hasExactTeamAccess (teamId, access) {
        const u = this.user()
        if (!u || !u.teams) {
          return false
        }
        return u.teams.some(ta => ta.access_level === access && ta.team.id === teamId)
      },
      // you have at least <role> to <teamId> or are an admin
      hasLimitedAccess (teamId) {
        if (this.is('admin')) { // can use admin here because they'll never see non-company teams
          return true
        }
        const u = this.user()
        if (!u || !u.teams) {
          return false
        }
        return u.teams.some(access => access.team.id === teamId)
      },
      hasMemberAccess (teamId) {
        if (this.is('admin')) { // can use admin here because they'll never see non-company teams
          return true
        }
        const u = this.user()
        if (!u || !u.teams) {
          return false
        }
        const relevantAccess = u.teams.filter(access => access.team.id === teamId) // filter just to relevant team
        return relevantAccess.some(access => ['manager', 'member'].includes(access.access_level))
      },
      hasManagerAccess (teamId) {
        if (this.is('admin')) { // can use admin here because they'll never see non-company teams
          return true
        }
        const u = this.user()
        if (!u || !u.teams) {
          return false
        }
        return u.teams.some(access => access.team.id === teamId && access.access_level === 'manager')
      },
      // you have at least <role> to any team or are an admin
      hasSomeMemberAccess () {
        const u = this.user()
        if (this.is('admin')) {
          return true
        }
        if (u && u.teams && u.teams.some(access => ['manager', 'member'].includes(access.access_level))) {
          return true
        } else {
          return false
        }
      },
      hasSomeManagerAccess () {
        const u = this.user()
        if (this.is('admin')) {
          return true
        }
        if (u && u.teams && u.teams.some(access => access.access_level === 'manager')) {
          return true
        } else {
          return false
        }
      },
      configureRollbar () {
        if (!Vue.rollbar) { // dev
          return
        }
        if (store && store.state.user && store.state.user.id) {
          Vue.rollbar.configure({
            payload: {
              person: {
                id: store.state.user.id,
                email: store.state.user.email
              }
            }
          })
        } else {
          Vue.rollbar.configure({
            payload: {} // no user data anymore
          })
        }
      }
    }

    Vue.prototype.$auth = auth

    const isNoAuth = (route) => {
      return route.path.includes('/auth/') || route.meta?.noAuth
    }
    let hasDoneRefresh = false
    // configure authentication route guards
    router.beforeEach((to, from, next) => {
      if (!hasDoneRefresh && auth.user() && !isNoAuth(to)) {
        // if you're logged in (localstorage) and NOT on an auth route
        // kick off a refresh one time (app started)
        // this moved here because app.vue doesn't have route information on created for some reason
        auth.refresh(true)
        hasDoneRefresh = true
      }

      // if the route isn't an auth route, it requires you to be logged in.  if you're not, redirect to login screen
      // we can also specify meta.noAuth
      if (!(isNoAuth(to)) && !auth.isLoggedIn()) {
        next({ name: 'login', params: { nextLink: to } })
      } else { // carry on to your intended route
        if (!auth.is('admin') && (to.name === 'project' || to.name === 'task')) { // these are admin only routes that weren't limited some other way
          next(from)
        } else {
          next() // make sure to always call next()! but only once
        }
      }
    })
  }
}

export default Auth
