import Vue from 'vue'
import { tzMo } from '../utils/time-utils'
import { fixMetricField, combineDataConnections } from '@/utils'

/* A fit-them-all commit */
export function basic (state, payload) {
  state[payload.key] = payload.value
}

export function onAppUserLoadComplete (state) {
  // easier debugging in vue devtools by making this a named function
  // this happens when the user logs in or is refreshed on page load
  Vue.set(state, 'appUserLoadComplete', true)
}

export function upsertStoredMetrics (state, metricData) {
  Object.keys(metricData).forEach(integration => {
    const metrics = Array.isArray(metricData[integration]) ? metricData[integration] : [metricData[integration]]
    const myMetrics = JSON.parse(JSON.stringify(state.liveMetrics))
    const theirMetrics = JSON.parse(JSON.stringify(state.activeUserMetrics))

    const getActiveSet = (metric) => { // use related data to determine if this is your metric or the active user's metric
      const activeUserId = state.activeUser?.id
      const currentUserId = state.user?.id
      if (parseInt(metric.user_id) === parseInt(activeUserId)) {
        return theirMetrics
      } else if (parseInt(metric.user_id) === parseInt(currentUserId)) {
        return myMetrics
      }
    }

    metrics.forEach(metric => {
      metric.integration = integration
      fixMetricField(metric)
      if (metric.payload) {
        combineDataConnections(metric.payload)
      }
      metric.date = tzMo(metric.event_datetime)

      const activeSet = getActiveSet(metric)
      if (!activeSet) {
        return // skip this metric, it's from a user we don't look at anymore
      }
      const existingMetric = activeSet.find(existingMetric => existingMetric.integration === integration && existingMetric.id === metric.id)
      if (existingMetric) {
        Object.assign(existingMetric, metric)
      } else {
        // these cards are for someone else, and you're not an admin
        if (activeSet === theirMetrics && state.user.role !== 'admin') {
          // you can only "see" this metric if you're on a team with that integration at at minimum member access
          const hasAccess = state.user.teams.some(teamAccess => teamAccess.access_level !== 'limited')
          if (hasAccess) {
            activeSet.push(metric)
          }
        } else {
          activeSet.push(metric)
        }
      }
    })
    Vue.set(state, 'liveMetrics', myMetrics)
    Vue.set(state, 'activeUserMetrics', theirMetrics)
  })
}

/* User */
export function setUser (state, payload) {
  payload && payload.id ? localStorage.setItem('user', JSON.stringify(payload)) : localStorage.removeItem('user')
  Vue.set(state, 'user', payload)
}

/* Full Page mode */
export function fullPage (state, payload) {
  // I think these 3 things should be moved to getters and use something more robust
  // this breaks during hot-reloads of inner-components of a full page
  state.isNavBarVisible = !payload
  state.isAsideVisible = !payload
  state.isFooterBarVisible = !payload
  const fullPageClasses = ['has-aside-left', 'has-navbar-fixed-top']
  fullPageClasses.forEach(htmlClass => {
    if (payload) {
      document.documentElement.classList.remove(htmlClass)
    } else {
      document.documentElement.classList.add(htmlClass)
    }
  })
}

/* Aside Desktop */
export function asideStateToggle (state, payload = null) {
  const htmlAsideClassName = 'has-aside-expanded'

  let isExpand

  if (payload !== null) {
    isExpand = payload
  } else {
    isExpand = !state.isAsideExpanded
  }

  if (isExpand) {
    document.documentElement.classList.add(htmlAsideClassName)
  } else {
    document.documentElement.classList.remove(htmlAsideClassName)
  }

  state.isAsideExpanded = isExpand
}

/* Aside Mobile */
export function asideMobileStateToggle (state, payload = null) {
  const htmlClassName = 'has-aside-mobile-expanded'

  let isShow

  if (payload !== null) {
    isShow = payload
  } else {
    isShow = !state.isAsideMobileExpanded
  }

  if (isShow) {
    document.documentElement.classList.add(htmlClassName)
  } else {
    document.documentElement.classList.remove(htmlClassName)
  }

  state.isAsideMobileExpanded = isShow
}

/* Aside Forced Active Key (when secondary submenu is open) */
export function asideActiveForcedKeyToggle (state, payload) {
  state.asideActiveForcedKey = payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null
}

/* Overlay */
export function overlayToggle (state, payload = null) {
  if (payload === null) {
    payload = !state.isOverlayVisible
  }

  state.isOverlayVisible = !!payload
}

export function clearSpecificVuex (state) { // removes anything user specific or that you shouldn't see after logout
  Vue.set(state, 'user', {})
  Vue.set(state, 'lat', 0)
  Vue.set(state, 'lon', 0)
  Vue.set(state, 'locationRequestTried', false)
  Vue.set(state, 'locationDenied', false)
  Vue.set(state, 'activeTeam', null)
  Vue.set(state, 'activeUser', null)
  Vue.set(state, 'wfuTableOptions', null)
  Vue.set(state, 'liveMetrics', [])
  Vue.set(state, 'activeUserMetrics', [])
  Vue.set(state, 'lastMetricSeen', null)
  Vue.set(state, 'latestMetricItem', null)
  Vue.set(state, 'connectedSockets', [])
  Vue.set(state, 'onReconnect', {})
  Vue.set(state, 'appUserLoadComplete', false)
  touchedSockets = []
}

let touchedSockets = [] // list of any socket we connected to
// only run the onReconnect to previously touched sockets
// this prevents double watch on startup if watch fires before connection
export function connectSocket (state, namespace) {
  const connections = state.connectedSockets.filter(connection => connection !== namespace)
  connections.push(namespace)
  if (touchedSockets.includes(namespace) && typeof state.onReconnect[namespace] === 'function') {
    state.onReconnect[namespace]()
  }
  if (!touchedSockets.includes(namespace)) {
    touchedSockets.push(namespace)
  }
  // we can't clear out onReconnect because we may get disconnected/reconnected multiple times with only a single page load/setup
  Vue.set(state, 'connectedSockets', connections)
}

export function disconnectSocket (state, namespace) {
  const connections = state.connectedSockets.filter(connection => connection !== namespace)
  // don't clear out the "onReconnect" here, they will get cleared manually or lost due to reload
  Vue.set(state, 'connectedSockets', connections)
}

export function setOnReconnect (state, { namespace, action }) {
  Vue.set(state, 'onReconnect', Object.assign({}, state.onReconnect, { [namespace]: action }))
}
