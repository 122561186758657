/**
 * @typedef LimitOptions
 * @property maxLength (-1) if > 3 then will limit text length to this (accounting for filled ...)
 * @property cutoffPosition ('middle') 'start' | 'middle' | 'end' for where to put the '...' when cutting text
 * @property replaceText ([]) pass an array of replaceables, can be either string, or array of [from, to] or objects of { selector, replacement }. selectors can be regex
 */

/**
 * transform x axis text with replacement text and length limiting functions
 * @param {*} chart dcjs chart (get from onCreate vue-dcjs callback)
 * @param {LimitOptions} options options for how to transform x axis entries
 */
function limitXAxisText (chart, options = null) {
  const d3 = window.vm?.$d3
  const { maxLength, cutoffPosition, replaceText, format } = Object.assign({ maxLength: -1, cutoffPosition: 'middle', replaceText: [] }, options)

  chart.on('renderlet', () => {
    chart.selectAll('.axis.x .tick text').each(function (c, i) {
      const node = d3.select(this)
      let text = chart.group().int2ord(i)
      if (format) {
        text = format(text)
      }
      replaceText.forEach(replacement => {
        if (typeof replacement === 'string') {
          text = text.replace(replacement, '') // take out anything that is just a string
        } else if (Array.isArray(replacement)) {
          text = text.replace(replacement[0], replacement[1])
        } else if (replacement?.selector && replacement?.replacement) {
          text = text.replace(replacement.selector, replacement.replacement)
        }
      })

      if (maxLength > 3 && text.length > maxLength) {
        if (cutoffPosition === 'start' || cutoffPosition === 'beginning') {
          text = '...' + text.slice(-1 * (maxLength - 3))
        } else if (cutoffPosition === 'center' || cutoffPosition === 'middle') {
          text = text.slice(0, Math.floor((maxLength - 3) / 2)) + '...' + text.slice(-1 * Math.ceil(maxLength / 2))
        } else if (cutoffPosition === 'end' || cutoffPosition === 'ending') {
          text = text.slice(0, maxLength - 3) + '...'
        }
      }

      node.text(text)
    })
  })

  // tickFormat only gets called on rendered ticks
  // keeping the oldFormat will fix issues with missing ticks
  // but setting the tickFormat function to preserve text will prevent rendering jumps from old text to transformed text
  const oldFormat = chart.xAxis().tickFormat()
  chart.xAxis().tickFormat((value, i, ticks) => {
    const text = d3.select(ticks[i]).text()
    return text?.length ? text : oldFormat(value, i, ticks)
  })
}

export {
  limitXAxisText
}
