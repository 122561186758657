import { parseVSV } from './data-utils'
import { tzMo, mo } from './time-utils'
import axios from '../network'

export function fixMetricField (metric) {
  Object.assign(metric, {
    simpleType: metric.type?.includes(':') ? metric.type.slice(0, metric.type.indexOf(':')) : metric.type,
    eventDatetime: tzMo(metric.event_datetime),
    date: mo(tzMo(metric.event_datetime).format('YYYY-MM-DD')), // d3 does better with a transformed date turned into a local date string
    eventHour: tzMo(metric.event_datetime).hour(),
    maps: metric.map_names,
    projects: metric.project_name ? [metric.project_name] : [],
    layers: metric.layer_names,
    dataSources: metric.data_sources,
    sketchTime: metric.sketch_time,
    processingTime: metric.processing_time
  })
  return metric
}

/**
 * New edit events come through with condensed data connections so you get {dataConnections: [{...full_object...}]} and { creates: [{... dataConnection: 0 ...}]}
 * @param {EditCompleted} payload
 * @returns
 */
export function combineDataConnections (payload) {
  if (!payload.dataConnections) {
    return // old schema
  }
  const { creates, modifies, deletes, invalidated, dataConnections } = payload
  const combine = (arr) => {
    arr.forEach(edit => {
      if (dataConnections[edit.dataConnection]) {
        edit.dataConnection = { ...dataConnections[edit.dataConnection] }
      }
    })
  }
  combine(creates)
  combine(modifies)
  combine(deletes)
  combine(invalidated)
}

export function fetchMergePayloads (ids, events) {
  return axios.post('/api/arc-events', { ids, payloadOnly: true }).then(response => {
    const payloads = parseVSV(response.data.result)
    const updates = []
    payloads.forEach(row => {
      const existingRow = events.find(r => r.id === row.id)
      const update = Object.assign({}, existingRow, row)
      combineDataConnections(update.payload)
      updates.push(update)
    })
    return Promise.resolve(updates)
  })
}
