import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
import workflowStoreModule from './tiptap-workflow-store'

Vue.use(Vuex)

const state = {
  /* User */
  appUserLoadComplete: false, // on initial load/login, we should wait for user info before letting certain things load
  user: {},
  todayMetricType: 'arc',

  // stored geolocation will persist across pages for time submission
  lat: 0,
  lon: 0,
  locationRequestTried: false, // have we tried fetching geolocation yet
  locationDenied: false,

  activeTeam: null, // reporting on a team-specific page
  activeUser: null, // reporting on a user-specific page

  wfuTableOptions: null, // if you edit/update records preserve filters/grouping

  /* NavBar */
  isNavBarVisible: true,

  /* FooterBar */
  isFooterBarVisible: true,

  /* Aside */
  isAsideVisible: true,
  isAsideExpanded: true,
  isAsideMobileExpanded: false,
  asideActiveForcedKey: null,
  lastMetricSeen: null,
  latestMetricItem: null,

  /* Overlay */
  isOverlayVisible: false,

  leftMenu: [],
  leftMenuBottom: [],

  liveMetrics: [],
  activeUserMetrics: [],

  connectedSockets: [], // namespace
  onReconnect: {} // namespace: action
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    workflow: workflowStoreModule
  }
})

export default store
