<template>
  <div id="app">
    <nav-bar />
    <aside-menu
      :menu="leftMenu"
      :menu-bottom="leftMenuBottom"
      @menu-click="() => null"/>
    <router-view class="main-app-view" />
    <footer-bar />
    <overlay />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import NavBar from '@/components/frames/NavBar'
import AsideMenu from '@/components/aside/AsideMenu'
import FooterBar from '@/components/frames/FooterBar'
import Overlay from './components/common/Overlay'
import { makeLeftMenu, makeLeftMenuBottom } from '@/menus'
import moment from 'moment-timezone'
import { titleCase } from '@/utils'

export default {
  name: 'App',
  components: {
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar
  },
  computed: {
    ...mapState([
      'leftMenu',
      'leftMenuBottom',
      'user',
      'activeUser',
      'activeteam'
    ])
  },
  created () {
    window.addEventListener('error', this.onWindowError)
    this.updateDocumentTitle()
  },
  mounted () {
    // use a compound watcher to update the doucment title whenever any of these things loads
    this.$watch(comp => ([comp.$route, comp.user, comp.activeUser, comp.activeTeam]), () => {
      // we watch activeTeam and activeUser to know once the data has been loaded in the wfu (it is asynchronous)
      this.updateDocumentTitle()
    })
  },
  methods: {
    onWindowError (evt) {
      // these are messages we want to catch in our handler
      const chunkMessages = [ // uses regex.test
        "SyntaxError: Unexpected token '<'",
        /Loading chunk [A-z-]* failed. \(missing: /,
        /Loading CSS chunk [A-z-]* failed./,
        "SyntaxError: expected expression, got '<'"
      ]
      // I think there will be some that we can't really catch
      // like TypeError: t is undefined or Cannot read property 'select' of undefined
      // I think these errors are too ambiguous as to what caused them
      if (evt && evt.message && chunkMessages.some(txt => new RegExp(txt, 'gi').test(evt.message))) {
        const lastTime = localStorage.getItem('contentRefreshTime')
        const now = moment()
        // this looks like we should try and fetch new content by refreshing
        // but only do it if we haven't in at least 12 hours
        console.log('A chunk error occurred. checking times', lastTime, now)
        if (!lastTime || now.diff(moment(lastTime), 'hours') > 12) {
          console.log('reloading...')
          localStorage.setItem('contentRefreshTime', now.format())
          window.location.reload()
        } else {
          console.log('Chose not to reload due to last time within 12 hours')
        }
      }
    },
    updateDocumentTitle () {
      // updates the document title per-page matching the following structure
      // ENV - Page Info | ChronoCards (shift info)
      let title = ''
      if (window.location.origin.includes('localhost')) {
        title += 'LOCAL - '
      } else if (window.location.origin.includes('dev.chrono.cards')) {
        title += 'DEV - '
      }

      if (typeof this.$route.meta?.title === 'function') {
        title += this.$route.meta.title(this.$route)
      } else if (this.$route.meta?.title) {
        title += this.$route.meta.title
      } else if (this.$route.name) {
        title += this.$route.name.split('-').map(t => titleCase(t)).join(' ')
      }

      title += ' | ChronoCards'
      document.title = title
    }
  },
  watch: {
    user () {
      // any changes to user (login, initial refresh, logout, role changed)
      // reconstruct menu according to roles
      this.$store.commit('basic', { key: 'leftMenu', value: makeLeftMenu(this.$auth) })
      this.$store.commit('basic', { key: 'leftMenuBottom', value: makeLeftMenuBottom(this.$auth) })
    }
  },
  beforeDestroy () {
    window.removeEventListener('error', this.onWindowError)
  }
}
</script>

<style scoped>
.main-app-view {
  min-height: calc(100vh - 59px - 40px);
}
@media screen and (max-width: 768px) {
  .main-app-view {
    min-height: calc(100vh - 59px - 64px);
  }
}
</style>
